import React, { useState } from "react";
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import GetFormParser from "components/GetFormParser";
import { PrimaryButton } from "shared/Buttons";
import { useDispatch } from "react-redux";
import {
  confirmEmailAdress,
  createPasswordAndFinish,
  startRegistration,
} from "api";
import LoadingIndicator from "shared/LoadingIndicator";
import { createStoreData } from "store";
import { useNavigate } from "react-router-dom";

const startFields = [
  // {
  //   name: "userInn",
  //   label: "ИНН",
  //   type: "text",
  //   placeholder: "Введите ваш инн",
  // },
  {
    name: "userName",
    label: "Фамилия Имя Отчество",
    type: "text",
    placeholder: "ФИО",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Введите ваш Email",
  },
  {
    name: "workAddress",
    label: "Место работы",
    type: "select",
    variants: [
      { value: "Admin", label: "МИД" },
      { value: "User", label: "МВД" },
    ],
  },
  {
    name: "role",
    label: "Должность",
    type: "select",
    variants: [
      { value: "Admin", label: "Админ" },
      { value: "User", label: "Пользователь" },
    ],
  },
  {
    name: "isMale",
    type: "radio",
    variants: [
      { value: true, label: "Мужчина" },
      { value: false, label: "Женщина" },
    ],
  },
];
const confirmField = [
  {
    name: "emailConfirmCode",
    label: "Подтвердите свою почту",
    type: "text",
    placeholder: "Введите код отправленный на почту",
  },
];
const createPassowrmField = [
  {
    name: "userPassword",
    label: "Придумайте пароль",
    type: "text",
    placeholder: "Введите пароль",
  },
];

export default function Registration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState("start");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  let isStart = status === "start",
    isConfirm = status === "confirmEmail",
    isCreatePassword = status === "createPassword";

  const submitHandler = (values) => {
    if (isStart && !Boolean(values.isMale)) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Выберите ваш пол",
          },
        })
      );
      return;
    }
    setLoading(true);
    if (isStart) {
      dispatch(
        startRegistration(
          { ...values, isMale: values.isMale === "true" },
          (success) => {
            if (success) {
              setStatus("confirmEmail");
            }
            setLoading(false);
          }
        )
      );
    } else if (isConfirm) {
      dispatch(
        confirmEmailAdress(values, (success) => {
          if (success) {
            setStatus("createPassword");
            dispatch(
              createStoreData({
                alert: {
                  severity: "success",
                  message: "Пожалуйста, придумайте пароль",
                },
              })
            );
          }
          setLoading(false);
        })
      );
    } else if (isCreatePassword) {
      dispatch(
        createPasswordAndFinish(values, (success) => {
          if (success) {
            setStatus("start");
            dispatch(
              createStoreData({
                alert: {
                  severity: "success",
                  message: "Ваши данные были отправлены в модерацию",
                },
              })
            );
            navigate("/");
          }
          setLoading(false);
        })
      );
    }
  };
  const initialValues = isStart
      ? {
          userName: "",
          phone: "",
          email: "",
          isMale: "",
        }
      : isConfirm
      ? { emailConfirmCode: "" }
      : isCreatePassword
      ? { userPassword: "" }
      : {},
    fields = isStart
      ? startFields
      : isConfirm
      ? confirmField
      : isCreatePassword
      ? createPassowrmField
      : {},
    title = isStart
      ? "Регистрация"
      : isConfirm
      ? "Email адрес"
      : isCreatePassword
      ? "Пароль"
      : "Заголовок не найден";

  return (
    <Container style={{ marginBottom: 50 }}>
      <Typography
        variant="h6"
        style={{ textAlign: "center", margin: sm ? "30px 0px" : "50px 0px" }}>
        {title}
      </Typography>
      <div className="flex_box" style={{ width: "100%" }}>
        <GetFormParser
          style={{ width: "100%", maxWidth: 500 }}
          fields={fields}
          initialValues={initialValues}
          onSubmit={submitHandler}
          action={
            <div className="flex_box">
              <PrimaryButton
                variant="contained"
                type="submit"
                style={{ width: 250 }}>
                {isLoading ? (
                  <LoadingIndicator small />
                ) : isStart ? (
                  "Зарегистрироваться"
                ) : isConfirm ? (
                  "Подтвердить почту"
                ) : isCreatePassword ? (
                  "Создать пароль"
                ) : (
                  "Статус не известный"
                )}
              </PrimaryButton>
            </div>
          }
          enableReinitialize
        />
      </div>
    </Container>
  );
}
