import { CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingIndicator({ primary = false, small }) {
  return (
    <div
      className="flex_box"
      style={{
        margin: small ? 0 : "20px 0px",
        justifyContent: "center",
        width: "100%",
      }}>
      <CircularProgress
        style={{ color: primary ? "#1058FB" : "#FFFFFF" }}
        size={small ? 20 : 40}
      />
    </div>
  );
}
