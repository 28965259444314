import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import JoditEditor from "jodit-react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { createStoreData } from "store";
import close from "assets/icons/Close.svg";
import { TextInput } from "shared/TextInput";
import { PrimaryButton } from "shared/Buttons";
import LoadingIndicator from "shared/LoadingIndicator";
import { createOrUpdateSection, getBase64, getCourseSections } from "api";
import { deleteCourseSection, deleteSectionTest } from "api/DELETE_requests";

export default function UpdateSectionDialog({ open = false, handleClose }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCourse, currentSection, tests } = useSelector(
    (store) => store
  );
  const editor = React.useRef(null);
  const fileSelector = React.useRef();
  const [isLoading, setLoading] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [joditContent, setJoditContent] = useState("");
  const [sectionCover, setSectionCover] = useState();
  const [hasVideo, setHasVideo] = useState(false);
  const [sectionSequenceNumber, setSectionSequenceNumber] = useState("");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (Boolean(Object.keys(currentSection).length) && open) {
      setSectionName(currentSection.sectionName);
      setSectionSequenceNumber(currentSection.sectionSequenceNumber);
      setHasVideo(currentSection.hasVideo);
      setJoditContent(
        Boolean(currentSection.documentHtml) ? currentSection.documentHtml : ""
      );
    }
  }, [currentSection, open]);

  const deleteSection = () => {
    dispatch(
      createStoreData({
        confirmRemoveDialog: {
          open: true,
          confirm: () => {
            if (
              Boolean(tests[currentSection.courseSectionId]) &&
              tests[currentSection.courseSectionId] !== "Not found"
            ) {
              dispatch(
                deleteSectionTest(
                  tests[currentSection.courseSectionId].testings[0].testingId,
                  (success) => {
                    if (success) {
                      dispatch(
                        deleteCourseSection(
                          currentSection.courseSectionId,
                          (success) => {
                            if (success) {
                              dispatch(
                                getCourseSections(
                                  currentCourse.learningCourseId
                                )
                              );
                              navigate(-1);
                            }
                          }
                        )
                      );
                    }
                  }
                )
              );
            } else {
              dispatch(
                deleteCourseSection(
                  currentSection.courseSectionId,
                  (success) => {
                    if (success) {
                      dispatch(
                        getCourseSections(currentCourse.learningCourseId)
                      );
                      refreshState();
                      navigate(-1);
                    }
                  }
                )
              );
            }
          },
        },
      })
    );
  };
  const saveSectionHTMLUpdate = async () => {
    if (!isLoading) {
      let values = {
        learningCourseId: currentCourse.learningCourseId,
        courseSectionId: currentSection.courseSectionId,
        sectionName,
        sectionSequenceNumber: parseInt(sectionSequenceNumber),
        hasVideo,
        documentHtml: joditContent,
        documentHtml2: "",
        data: currentSection.data,
      };
      if (sectionCover) {
        values.sectionImageBase64 = await getBase64(sectionCover);
      }
      setLoading(true);
      dispatch(
        createOrUpdateSection(values, (json) => {
          setLoading(false);
          if (json) {
            let matchedSection = json
              ? json.courseSections.filter(
                  (item) =>
                    item.courseSectionId ===
                    parseInt(currentSection.courseSectionId)
                )
              : [];
            if (Boolean(matchedSection.length)) {
              setLoading(false);
              dispatch(createStoreData({ currentSection: matchedSection[0] }));
            } else {
              dispatch(
                createStoreData({
                  alert: {
                    severity: "error",
                    message: "Модуль не найден",
                  },
                })
              );
            }
            dispatch(
              createStoreData({
                alert: { severity: "success", message: "Успешно" },
              })
            );
            refreshState();
          }
        })
      );
    }
  };

  const config = {
    i18n: "ru",
    language: "ru",
    readonly: false,
    activeButtonsInReadOnly: ["source", "fullsize", "print", "about", "dots"],
    toolbarButtonSize: "middle",
    theme: "default",
    spellcheck: true,
    editorCssClass: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    direction: "",
    debugLanguage: false,
    tabIndex: -1,
    toolbar: true,
    enter: "P",
    useSearch: false,
    useSplitMode: false,
    colorPickerDefaultTab: "background",
    imageDefaultWidth: 300,
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "video",
      "table",
      "link",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "symbol",
      "fullsize",
      "print",
      "about",
    ],
    buttonsXS: [
      "bold",
      "image",
      "|",
      "brush",
      "paragraph",
      "|",
      "align",
      "|",
      "undo",
      "redo",
      "|",
      "eraser",
      "dots",
    ],
    uploader: { insertImageAsBase64URI: true },
    events: {},
    textIcons: false,
  };
  const refreshState = () => {
    setSectionName("");
    setSectionCover("");
    setSectionSequenceNumber("");
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.paper100 }}
      scroll="body"
      disableEnforceFocus>
      {
        <DialogContent dividers={false}>
          <div
            className="flex_box"
            style={{ justifyContent: "space-between", marginBottom: 20 }}>
            <Typography variant="h6">{currentSection.sectionName}</Typography>
            <img
              style={{ cursor: "pointer" }}
              src={close}
              alt="close_icon"
              onClick={refreshState}
            />
          </div>
          <div
            className="flex_box"
            style={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: 40,
            }}>
            <div style={{ width: sm ? "100%" : "50%" }}>
              <Typography variant="body2" className={classes.subtitle}>
                Название раздела
              </Typography>
              <TextInput
                placeholder="Введите название"
                onChange={(event) => setSectionName(event.target.value)}
                value={sectionName}
                style={{ width: sm ? "100%" : "50%" }}
              />
            </div>
            <div style={{ width: sm ? "100%" : "50%", marginTop: sm ? 12 : 0 }}>
              <Typography variant="body2" className={classes.subtitle}>
                Порядковый номер
              </Typography>
              <TextInput
                placeholder="Введите номер"
                onChange={(event) =>
                  setSectionSequenceNumber(event.target.value)
                }
                value={sectionSequenceNumber}
                style={{ width: sm ? "100%" : "50%" }}
              />
            </div>
            <div
              style={{ width: sm ? "100%" : "50%", marginTop: sm ? 12 : 20 }}>
              <FormControlLabel
                checked={hasVideo}
                onChange={(event) => setHasVideo(Boolean(event.target.checked))}
                control={<Checkbox />}
                label="Содержит видео материал"
              />
            </div>
          </div>
          <JoditEditor
            ref={editor}
            value={joditContent}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => setJoditContent(newContent)} // preferred to use only this option to update the content for performance reasons
          />
          <div
            style={{
              width: sm ? "100%" : "50%",
              marginTop: sm ? 12 : 20,
            }}></div>
          <div
            className="flex_box"
            style={{
              gap: 20,
              margin: "20px 0px",
            }}>
            <Button variant="delete" onClick={deleteSection}>
              Удалить раздел
            </Button>
            <PrimaryButton
              style={{ width: 150 }}
              onClick={saveSectionHTMLUpdate}
              disabled={isLoading}>
              {isLoading ? <LoadingIndicator small /> : "Сохранить"}
            </PrimaryButton>
          </div>
          <input
            hidden
            ref={fileSelector}
            type="file"
            name="CourserImageBase64"
            onChange={(event) => {
              setSectionCover(event.target.files[0]);
            }}
            except=".png, .jpeg, .jpg"
          />
        </DialogContent>
      }
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper100: {
    minWidth: "calc(100% - 320px)",
  },
  paper700: {
    minWidth: 700,
  },
  subtitle: {
    fontWeight: "600 !important",
    color: "#000000",
    marginBottom: "8px !important",
  },
  fileInput: {
    "& .Mui-disabled": { cursor: "pointer" },
    "& input::placeholder": {
      opacity: 1,
    },
  },
  deleteButton: {
    "&.MuiButton-root": {
      minHeight: 32,
      fontWeight: 700,
      color: "#ed1528",
      borderColor: "#ed1528",
    },
    "&.MuiButton-root:hover": {
      borderColor: "#ed1528",
    },
  },
  addNewBtn: {
    backgroundColor: "transparent",
    color: "#1058FB",
    position: "absolute",
    top: 0,
    right: 0,
  },
  addPillowBtn: {
    "&.MuiButton-root": {
      backgroundColor: "#EFEFEF",
      borderRadius: 25,
      fontSize: 14,
      fontWeight: 700,
      color: "#1058FB",
      boxShadow: "none",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
}));
