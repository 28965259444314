import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";

import { updateStoreData } from "store";
import testIcon from "assets/icons/test.svg";
import plusIcon from "assets/icons/bx-plus.svg";
import editIcon from "assets/icons/bxs-edit-alt.svg";
import AddTestingDialog from "components/AddTestingDialog";
import editWhiteIcon from "assets/icons/bxs-edit-alt-white.svg";

export function EditTest({ sectionTest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, quests, currentSection, currentCourse } = useSelector(
    (store) => store
  );
  const [addTestingDialog, setAddTestingDialog] = useState(false);

  let test = Boolean(Object.keys(sectionTest).length),
    testQuests = test && quests[sectionTest.testingId],
    areThereTestQuests = Boolean(testQuests) && Boolean(testQuests.length);
  return (
    <div>
      <div
        className="flex_box"
        style={{ justifyContent: "space-between", marginBottom: 34 }}>
        <Typography variant="h6">Тестирование</Typography>
        {user.isAdmin && test && (
          <Button
            size="small"
            variant="contained"
            onClick={() => setAddTestingDialog(true)}
            startIcon={
              <img
                src={editWhiteIcon}
                style={{ width: 20, height: 20 }}
                alt=""
              />
            }>
            Редактировать тестирование
          </Button>
        )}
      </div>
      {areThereTestQuests ? (
        testQuests.map((item, index) => {
          return (
            <React.Fragment key={item.testingQuestId}>
              <div
                className="flex_box"
                style={{ justifyContent: "space-between" }}>
                <Typography
                  variant="body1"
                  style={{ color: "#1058FB", fontWeight: 600 }}>
                  {`Вопрос ${index + 1}`}
                </Typography>
                {user.isAdmin && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      dispatch(
                        updateStoreData({
                          key: "addTestingQuestDialog",
                          data: {
                            open: true,
                            testingId: sectionTest.testingId,
                            testingQuest: item,
                          },
                        })
                      )
                    }
                    startIcon={
                      <img
                        src={editIcon}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                    }>
                    Редактировать вопрос
                  </Button>
                )}
              </div>
              <Typography variant="body1" style={{ marginTop: 8 }}>
                {item.quest}
              </Typography>
              <div
                style={{
                  padding: "8px 18px",
                  backgroundColor: "#F1F2F3",
                  borderRadius: 4,
                  marginTop: 20,
                }}>
                {Boolean(item.answers.length) &&
                  item.answers.map((answer, index) => (
                    <div
                      style={{ width: "100%", marginTop: 5 }}
                      key={index + answer}>
                      <FormControlLabel
                        style={{ cursor: "default" }}
                        checked={answer.isRightAnswer}
                        control={
                          <Checkbox
                            style={{ cursor: "default" }}
                            disableRipple
                          />
                        }
                        label={answer.answer}
                      />
                    </div>
                  ))}
              </div>
              <Divider
                style={{
                  backgroundColor: "#BDBDBD",
                  margin: "24px 0px",
                }}
              />
            </React.Fragment>
          );
        })
      ) : (
        <div className="flex_box" style={{ minHeight: "calc(100vh - 500px)" }}>
          <span style={{ width: 350, textAlign: "center" }}>
            <img src={testIcon} alt="" />
            <Typography
              variant="subtitle1"
              style={{ color: "#828282", margin: "30px 0px" }}>
              Добавьте материалы в раздел
            </Typography>
            {user.isAdmin && (
              <Button
                type="button"
                variant="outlined"
                style={{ width: 200 }}
                onClick={() =>
                  test
                    ? dispatch(
                        updateStoreData({
                          key: "addTestingQuestDialog",
                          data: {
                            open: true,
                            testingId: sectionTest.testingId,
                          },
                        })
                      )
                    : setAddTestingDialog(true)
                }>
                Добавить
              </Button>
            )}
          </span>
        </div>
      )}
      {user.isAdmin && test && areThereTestQuests && (
        <Button
          size="small"
          variant="outlined"
          style={{
            width: 200,
          }}
          onClick={() =>
            dispatch(
              updateStoreData({
                key: "addTestingQuestDialog",
                data: {
                  open: true,
                  testingId: sectionTest.testingId,
                },
              })
            )
          }
          startIcon={
            <img src={plusIcon} style={{ width: 20, height: 20 }} alt="" />
          }>
          Добавить вопрос
        </Button>
      )}
      {areThereTestQuests && (
        <div className="flex_box" style={{ marginTop: 32 }}>
          <Button
            variant="green"
            style={{ width: 250 }}
            onClick={() =>
              navigate(`/aboute-course/${currentCourse.learningCourseId}`)
            }>
            В главное меню
          </Button>
        </div>
      )}
      <AddTestingDialog
        open={addTestingDialog}
        handleClose={() => setAddTestingDialog(false)}
        courseSection={currentSection}
        initialValues={
          Boolean(Object.keys(sectionTest).length)
            ? {
                testingId: sectionTest.testingId,
                countAttempts: sectionTest.countAttempts,
                minimumRightAnswers: sectionTest.minimumRightAnswers,
                testingTime: sectionTest.testingTime,
                testingSequenceNumber: sectionTest.testingSequenceNumber,
              }
            : {
                countAttempts: "",
                minimumRightAnswers: "",
                testingTime: "",
                testingSequenceNumber: "",
              }
        }
      />
    </div>
  );
}
