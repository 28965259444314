import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Typography } from "@mui/material";
import * as Yup from "yup";

import close from "assets/icons/Close.svg";
import { PrimaryButton } from "shared/Buttons";
import GetFormParser from "./GetFormParser";
import { useDispatch } from "react-redux";
import { createOrUpdateCourse, getBase64, getCoursesList } from "api";
import LoadingIndicator from "shared/LoadingIndicator";
import { TextInput } from "shared/TextInput";
import { createStoreData } from "store";

export default function AddNewCourseDialog({
  open = false,
  handleClose,
  course,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileSelector = React.useRef();
  const [isLoading, setLoading] = useState(false);
  const [CourserImage, setCourserImage] = useState(null);

  const addNewCourse = async (values) => {
    if (!Boolean(course) && !CourserImage) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Загрузите обложку",
          },
        })
      );
      return;
    }
    if (CourserImage) {
      values.courserImageBase64 = await getBase64(CourserImage);
    } else {
      values.courserImageBase64 = course?.courserImageBase64;
    }
    if (course) {
      values.learningCourseId = course?.learningCourseId;
    }
    setLoading(true);
    dispatch(
      createOrUpdateCourse(values, (success) => {
        setLoading(false);
        if (success) {
          dispatch(
            getCoursesList(false, (json) => {
              if (Boolean(json)) {
                dispatch(
                  createStoreData({
                    currentCourse: json.filter(
                      (item) =>
                        item.learningCourseId === course?.learningCourseId
                    )[0],
                  })
                );
              }
            })
          );
          refreshState();
        }
      })
    );
  };

  const refreshState = () => {
    setCourserImage(null);
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.content }}
      scroll="body">
      <DialogContent dividers={false}>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 20 }}>
          <Typography variant="h6">
            {course ? "Редактирование" : "Добавить курс"}
          </Typography>
          <img
            src={close}
            alt="close_icon"
            onClick={refreshState}
            style={{ cursor: "pointer" }}
          />
        </div>
        {isLoading ? (
          <div className="flex_box" style={{ minHeight: 322 }}>
            <LoadingIndicator primary />
          </div>
        ) : (
          <GetFormParser
            fields={fields}
            initialValues={{
              courseName: course ? course?.courseName : "",
              description: course ? course?.description : "",
              targetAudience: course ? course?.targetAudience : "",
            }}
            onSubmit={addNewCourse}
            validationSchema={validation}
            action={
              <>
                <Typography
                  variant="body2"
                  className={classes.subtitle}
                  style={{ marginBottom: 8 }}>
                  Обложка
                </Typography>
                <TextInput
                  className={classes.fileInput}
                  placeholder="Выберите картинку"
                  onClick={() => fileSelector.current.click()}
                  value={
                    Boolean(CourserImage)
                      ? CourserImage.name
                      : Boolean(course?.learningCourseId)
                      ? course?.courserImageBase64.split("nameSplitter*")[0]
                      : ""
                  }
                  disabled
                  fullWidth
                />
                <div className="flex_box" style={{ marginTop: 30 }}>
                  <PrimaryButton
                    variant="contained"
                    type="submit"
                    style={{ width: 300 }}
                    fullWidth>
                    {course ? "Сохранить" : "Создать курс"}
                  </PrimaryButton>
                </div>
              </>
            }
          />
        )}
        <input
          hidden
          ref={fileSelector}
          type="file"
          name="CourserImageBase64"
          onChange={(event) => {
            setCourserImage(event.target.files[0]);
          }}
          except=".png, .jpeg, .jpg"
        />
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 796,
    minHeight: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: "24px 26px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 16px",
      },
    },
  },
  subtitle: {
    fontWeight: "600 !important",
    color: "#000000",
  },
  fileInput: {
    "& .Mui-disabled": { cursor: "pointer" },
    "& input::placeholder": {
      opacity: 1,
    },
  },
}));

const fields = [
  {
    name: "courseName",
    label: "Название курса",
    type: "text",
    placeholder: "Введите название",
  },
  {
    name: "description",
    label: "Описание",
    type: "text",
    placeholder: "Добавьте описание",
    multiline: true,
  },
  {
    name: "targetAudience",
    label: "Для кого",
    type: "text",
    placeholder: "Введите аудиторию",
  },
];

const validation = Yup.object({
  courseName: Yup.string().required("Заполните поле"),
  description: Yup.string().required("Заполните поле"),
  targetAudience: Yup.string().required("Заполните поле"),
});
