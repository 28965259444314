export async function getBase64(file, addSplitter = true) {
  return new Promise((resolve, reject) => {
    let fileNameArr = file.name.split(".");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        addSplitter
          ? `${file.name}nameSplitter*data:application/${
              fileNameArr[fileNameArr.length - 1]
            };base64,${reader.result.split(",")[1]}`
          : reader.result
      );
    reader.onerror = (error) => reject(error);
  });
}
export const getShortString = (string, maxLength, isLink = false) => {
  if (string.length < maxLength) {
    return string;
  }

  if (isLink) {
    let start = string.slice(0, maxLength - 9),
      end = string.split("").reverse().join("").slice(0, 6);
    return `${start}...${end}`;
  } else {
    let strArr = string.split("."),
      start = strArr[0].slice(0, maxLength - (10 + strArr[1].length)),
      end = `${strArr[0].split("").reverse().join("").slice(0, 6)}.${
        strArr[1]
      }`;
    let result = `${start}...${end}`;
    return result;
  }
};

export const getUrlString = (obj) => {
  let url = "";
  let keys = Object.keys(obj).filter((key) => Boolean(obj[key]));

  keys.forEach(
    (key, index) =>
      (url += `${key}=${obj[key]}${keys.length === index + 1 ? "" : "&"}`)
  );

  return url;
};
