import moment from "moment";
import cookie_js from "cookie_js";
import { makeRequest } from "api";
import { createStoreData } from "store";
import { getCourseSections, getUserProfile } from "./GET_requests";
import { initialState } from "store/initialState";

export const authorization = (data, callback) => (dispatch) => {
  makeRequest("/Auth/AuthByPassword", dispatch, "post", data).then((json) => {
    if (json) {
      dispatch(getUserProfile(json.sessionId));
      callback();
      cookie_js.set("kurs_sessionId", json.sessionId, {
        expires: new Date(
          moment().add(1, "day").toLocaleString()
        ).toUTCString(),
        path: "/",
      });
    }
  });
};
export const getUsersList =
  (params, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      "/Account/SearchUsers/{sessionId}",
      dispatch,
      "post",
      params
    ).then((json) => {
      dispatch(
        createStoreData({ usersList: Boolean(json) ? json : "Not found" })
      );
      callback();
    });
  };
export const createOrUpdateCourse = (data, callback) => (dispatch) => {
  makeRequest(
    "/LearningCourse/SetCourse/{sessionId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    if (json) {
      callback(json.success);
    } else {
      callback(false);
    }
  });
};
export const createOrUpdateSection = (data, callback) => (dispatch) => {
  makeRequest(
    "/LearningCourse/SetCourseSection/{sessionId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    if (json) {
      dispatch(getCourseSections(data.learningCourseId, callback));
    } else {
      callback(false);
    }
  });
};
export const createOrUpdateTesting = (data, callback) => (dispatch) => {
  makeRequest(
    "/LearningCourse/SetTesting/{sessionId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    if (json) {
      callback(json.id);
    } else {
      callback(false);
    }
  });
};
export const createOrUpdateTestingQuest = (data, callback) => (dispatch) => {
  makeRequest(
    "/LearningCourse/SetTestingQuest/{sessionId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    if (json) {
      callback(json.success);
    } else {
      callback(false);
    }
  });
};
export const startRegistration = (data, callback) => (dispatch) => {
  makeRequest("/Registration/StartRegistration", dispatch, "post", data).then(
    (json) => {
      if (json && json.registrationId) {
        cookie_js.set("app_registrationId", json.registrationId);
      }
      callback(Boolean(json));
    }
  );
};
export const confirmEmailAdress = (data, callback) => (dispatch) => {
  makeRequest(
    "/Registration/ConfirmEmail/{registrationId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    callback(Boolean(json));
  });
};
export const createPasswordAndFinish = (data, callback) => (dispatch) => {
  makeRequest(
    "/Registration/CompleteRegistration/{registrationId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    cookie_js.remove("app_registrationId");
    callback(Boolean(json));
  });
};
export const getOrganizations =
  (params = { cityId: 0 }) =>
  (dispatch) => {
    makeRequest(
      "/Account/GetOrganizations/{sessionId}",
      dispatch,
      "post",
      params
    ).then((json) => {
      let parsedData = [];
      if (json) {
        parsedData = json.map((organization) => ({
          label: organization.organizationName,
          value: organization.organizationId,
        }));
      }
      dispatch(
        createStoreData({
          organizationsList: Boolean(json) ? json : "Not found",
          organizations: parsedData,
        })
      );
    });
  };
export const logout = () => (dispatch) => {
  makeRequest("/Auth/Logout/{sessionId}", dispatch, "post").then((json) => {
    cookie_js.remove("kurs_sessionId");
    dispatch(
      createStoreData({
        ...initialState,
        user: { sessionId: "", isAdmin: false, isSuperAdmin: false },
      })
    );
  });
};
export const searchRegisteredUsers =
  (params = { email: "", phone: "", userName: "" }) =>
  (dispatch) => {
    makeRequest(
      "/Registration/SearchRegisteredUsers/{sessionId}",
      dispatch,
      "post",
      params
    ).then((json) => {
      if (json) {
        dispatch(
          createStoreData({
            registeredUsers:
              Boolean(json) && Boolean(json.length) ? json : "Not found",
          })
        );
      }
    });
  };
export const addOrUpdateUserProfile = (data, callback) => (dispatch) => {
  makeRequest(
    "/Account/SetUserAccount/{sessionId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    if (json) {
      dispatch(getUsersList({}));
    }
    callback(Boolean(json));
  });
};
export const deleteUserProfile = (userId, callback) => (dispatch) => {
  makeRequest(
    `/Account/DeleteUser/{sessionId}/${userId}`,
    dispatch,
    "post"
  ).then((json) => {
    if (json) {
      dispatch(getUsersList({}));
    }
    callback(Boolean(json));
  });
};
export const subscribeCourse = (learningCourseId) => (dispatch) => {
  makeRequest(
    `/LearningCourse/SubscribeCourse/{sessionId}/${learningCourseId}`,
    dispatch,
    "post"
  ).then((json) => {
    dispatch(
      createStoreData({
        alert: {
          severity: json ? "success" : "error",
          message: json ? "Успешно подписались" : "Не удалось подписаться",
        },
      })
    );
  });
};
export const setCompleteCourseSection =
  (learningCourseId, courseSectionId, callback) => (dispatch) => {
    makeRequest(
      `/LearningCourse/SetCompleteCourseSection/{sessionId}/${learningCourseId}/${courseSectionId}`,
      dispatch,
      "post"
    ).then((json) => {
      callback(json);
    });
  };
export const createUserAccount = (data, callback) => (dispatch) => {
  makeRequest(
    "/Registration/CreateUserAccount/{sessionId}",
    dispatch,
    "post",
    data
  ).then((json) => {
    if (json) {
      dispatch(searchRegisteredUsers());
      dispatch(getUsersList({}, () => callback(true)));
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "Аккаунт успешно создан",
          },
        })
      );
    } else {
      callback(false);
    }
  });
};
export const deleteUser = (userId) => (dispatch) => {
  makeRequest(
    `/Account/DeleteUser/{sessionId}/${userId}`,
    dispatch,
    "post"
  ).then((json) => {
    if (json) {
      dispatch(getUsersList({}));
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "Пользователь успешно удален",
          },
        })
      );
    }
  });
};
export const deleteRegistrationRequest = (registrationId) => (dispatch) => {
  makeRequest(
    `/Registration/DeleteRegistrationRequest/{sessionId}/${registrationId}`,
    dispatch,
    "post"
  ).then((json) => {
    if (json) {
      dispatch(searchRegisteredUsers());
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "Заявка успешно удалена",
          },
        })
      );
    }
  });
};
export const uploadFile = (formData, callback) => (dispatch) => {
  fetch("https://training.mlsp.gov.kg/upload", {
    method: "post",
    body: formData,
  }).then((data) => {
    data.json().then((json) => {
      callback(Boolean(json.url) ? json.url : undefined);
    });
  });
};
