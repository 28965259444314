import React, { useState } from "react";
import { Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import testIcon from "assets/icons/test.svg";
import { PrimaryButton } from "shared/Buttons";

export default function PassTest({ sectionTest, setFinishBtnDisabled }) {
  const { quests } = useSelector((store) => store);
  const [userAnswers, setUserAnswer] = useState({});
  const [currentQuest, setCurrentQuest] = useState(0);

  React.useEffect(() => {
    if (typeof userAnswers[currentQuest.testingQuestId] !== "undefined") {
      setFinishBtnDisabled(false);
    }
  }, [userAnswers, setFinishBtnDisabled, currentQuest]);
  // const refreshState = () => {
  //   setUserAnswer({});
  //   setCurrentQuest(0);
  // };
  let testQuests = quests[sectionTest.testingId],
    areThereTestQuests = Boolean(testQuests) && Boolean(testQuests.length);
  return (
    <div style={{ overflow: "hidden" }}>
      {areThereTestQuests ? (
        testQuests.map(
          (quest, index) =>
            currentQuest === index && (
              <div
                className="testingQuest step_animated_left"
                key={quest.testingQuestId}>
                <div
                  className="flex_box"
                  style={{
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 500 }}>{`Вопрос №${
                    index + 1
                  }`}</Typography>
                </div>
                <Divider style={{ height: 0.4, backgroundColor: "#C4C4C4" }} />
                <Typography variant="body1" style={{ margin: "16px 0px" }}>
                  {quest.quest}
                </Typography>
                <Divider style={{ height: 0.4, backgroundColor: "#C4C4C4" }} />
                <Typography
                  variant="body2"
                  style={{
                    color: "#757575",
                    fontWeight: 500,
                    marginTop: 16,
                  }}>
                  Ответы:
                </Typography>
                <ul className="cyrilic">
                  {quest.answers.map((answer, answerIndex) => (
                    <li
                      key={answerIndex}
                      onClick={() => {
                        if (
                          typeof userAnswers[quest.testingQuestId] ===
                          "undefined"
                        ) {
                          setUserAnswer((state) => {
                            let newState = { ...state };
                            newState[quest.testingQuestId] = answer;
                            return newState;
                          });
                        }
                      }}
                      data-is-correct={`${
                        typeof userAnswers[quest.testingQuestId] !== "undefined"
                          ? userAnswers[quest.testingQuestId].answer ===
                            answer.answer
                            ? userAnswers[quest.testingQuestId].isRightAnswer
                            : answer.isRightAnswer
                          : "null"
                      }`}>
                      {answer.answer}
                    </li>
                  ))}
                </ul>
                <div className="flex_box" style={{ marginTop: 25 }}>
                  {index + 1 !== testQuests.length && (
                    <PrimaryButton
                      variant="contained"
                      onClick={() => {
                        setCurrentQuest(index + 1);
                      }}
                      disabled={
                        typeof userAnswers[quest.testingQuestId] === "undefined"
                      }>
                      Следующий вопрос
                    </PrimaryButton>
                  )}
                </div>
              </div>
            )
        )
      ) : (
        <div className="flex_box" style={{ minHeight: "calc(100vh - 500px)" }}>
          <span style={{ width: 350, textAlign: "center" }}>
            <img src={testIcon} alt="" />
            <Typography
              variant="subtitle1"
              style={{ color: "#828282", margin: "30px 0px" }}>
              Добавьте материалы в раздел
            </Typography>
          </span>
        </div>
      )}
    </div>
  );
}
