import { makeRequest } from "api";
import { updateStoreData } from "store";
import { createStoreData } from "store";
import { getOrganizations } from "./POST_requests";

export const getCoursesList =
  (isUser, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/LearningCourse/GetCourses/{sessionId}/false`,
      dispatch,
      "get"
    ).then((json) => {
      let subscribedCourses = json.filter((course) => course.subscribed);
      callback(json);
      subscribedCourses.forEach((course) => {
        if (isUser) {
          dispatch(getStatusPassageCourse(course.learningCourseId));
        }
        dispatch(getCourseSections(course.learningCourseId));
      });
      dispatch(
        createStoreData(
          json
            ? {
                subscribedCourses: subscribedCourses,
                coursesList: json,
              }
            : {
                alert: {
                  severity: "error",
                  message: "Не удалось получить список курсов",
                },
              }
        )
      );
    });
  };
export const getCourseSections = (courseId, callback) => (dispatch) => {
  makeRequest(
    `/LearningCourse/GetCourseSections/{sessionId}/${courseId}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "sections",
        data: {
          [courseId]: Boolean(json) ? json : { courseSections: [] },
        },
      })
    );
    if (callback) {
      callback(json);
    }
    if (!Boolean(json)) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Не удалось получить разделы",
          },
        })
      );
    }
  });
};
export const getUserData = (userId) => (dispatch) => {
  makeRequest(
    `/Account/GetUserAccount/{sessionId}/${userId}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "usersData",
        data: {
          [userId]: Boolean(json) ? json : "Not found",
        },
      })
    );
  });
};
export const getUserProfile = (sessionId) => (dispatch) => {
  makeRequest(`/Profile/GetUserProfile/${sessionId}`, dispatch, "get").then(
    (json) => {
      if (json) {
        let isAdmin = json.role === "Admin",
          isSuperAdmin = json.role === "SuperAdmin";
        if (isAdmin) {
          dispatch(getCities());
          dispatch(getOrganizations());
        }
        dispatch(getCoursesList(!isAdmin && !isSuperAdmin));
        dispatch(
          createStoreData({
            user: {
              ...json,
              sessionId,
              isAdmin,
              isSuperAdmin,
            },
          })
        );
      }
    }
  );
};
export const getAccount = (userId) => (dispatch) => {
  makeRequest(
    `/Account/GetUserAccount/{sessionId}/${userId}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "accounts",
        data: { [userId]: Boolean(json) ? json : "Not found" },
      })
    );
  });
};
export const getRegistrationStatus =
  (registrationId, callback) => (dispatch) => {
    makeRequest(
      `/Registration/GetRegistrationStatus/${registrationId}`,
      dispatch,
      "get"
    ).then((json) => {
      callback(Boolean(json));
      dispatch(
        updateStoreData({
          key: "registrationStatuses",
          data: { [registrationId]: Boolean(json) ? json : "Not found" },
        })
      );
    });
  };
export const getCities = () => (dispatch) => {
  makeRequest("/Account/GetCities/{sessionId}", dispatch, "get").then(
    (json) => {
      dispatch(
        createStoreData({
          citiesList: Boolean(json) ? json : "Not found",
        })
      );
    }
  );
};
export const getDepartments = (organizationId, callback) => (dispatch) => {
  makeRequest(
    `/Account/GetDepartments/{sessionId}/${organizationId}`,
    dispatch,
    "get"
  ).then((json) => {
    let parsedData = [];
    if (json) {
      parsedData = json.map((department) => ({
        label: department.departmentName,
        value: department.departmentId,
      }));
    }
    callback(Boolean(parsedData.length) ? parsedData : false);
    dispatch(
      updateStoreData({
        key: "departments",
        data: {
          [organizationId]: Boolean(json) ? { ...json, parsedData } : [],
        },
      })
    );
  });
};
export const getPositions = (organizationId, callback) => (dispatch) => {
  makeRequest(
    `/Account/GetPositions/{sessionId}/${organizationId}`,
    dispatch,
    "get"
  ).then((json) => {
    let parsedData = [];
    if (json) {
      parsedData = json.map((position) => ({
        label: position.positionName,
        value: position.positionId,
      }));
    }
    callback(Boolean(parsedData.length) ? parsedData : false);
    dispatch(
      updateStoreData({
        key: "positions",
        data: {
          [organizationId]: Boolean(json)
            ? { ...json, parsedData }
            : "Not found",
        },
      })
    );
  });
};
export const getStatusPassageCourse = (learningCourseId) => (dispatch) => {
  makeRequest(
    `/LearningCourse/GetStatusPassageCourse/{sessionId}/${learningCourseId}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "courseStatuses",
        data: {
          [learningCourseId]: Boolean(json)
            ? json
            : { completedCourseSectionIds: [] },
        },
      })
    );
  });
};
export const getSectionTests = (courseSectionId) => (dispatch) => {
  makeRequest(
    `/LearningCourse/GetTestings/{sessionId}/${courseSectionId}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "tests",
        data: {
          [courseSectionId]:
            Boolean(json) && Boolean(json.testings.length) ? json : "Not found",
        },
      })
    );
  });
};
export const getTestingQuests = (testingId, callback) => (dispatch) => {
  makeRequest(
    `/LearningCourse/GetTestingQuests/{sessionId}/${testingId}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "quests",
        data: {
          [testingId]: Boolean(json) ? json : "Not found",
        },
      })
    );
    if (callback) {
      callback(json);
    }
  });
};
export const getYouTubeVideoData = (url, callback) => {
  fetch(`https://noembed.com/embed?dataType=json&url=${url}`)
    .then((res) => res.json())
    .then((data) => callback(data));
};
