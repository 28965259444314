import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import close from "assets/icons/Close.svg";
import { PrimaryButton } from "shared/Buttons";
import { updateStoreData } from "store";
import { makeStyles } from "@mui/styles";
import {
  createUserAccount,
  getDepartments,
  getRegistrationStatus,
  getPositions,
} from "api";
import LoadingIndicator from "shared/LoadingIndicator";
import * as Yup from "yup";
import GetFormParser from "./GetFormParser";

export default function UserRequestDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userRequestDialog, registrationStatuses, organizations } =
    useSelector((store) => store);
  const [user, setUser] = useState({ userId: "" });
  const [isLoading, setLoading] = useState(false);
  const [departmentsState, setDepartments] = useState([]);
  const [positionsState, setPositions] = useState([]);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  let registrationId = userRequestDialog.user.registrationId;

  React.useEffect(() => {
    if (
      userRequestDialog.open &&
      registrationStatuses[registrationId] !== "Not found" &&
      !Boolean(registrationStatuses[registrationId])
    ) {
      setUser(userRequestDialog.user);
      setLoading(true);
      dispatch(getRegistrationStatus(registrationId, () => setLoading(false)));
    }
  }, [dispatch, registrationStatuses, userRequestDialog, user, registrationId]);

  const handleClose = () => {
    dispatch(
      updateStoreData({ key: "userRequestDialog", data: { open: false } })
    );
  };
  const fields = [
    {
      name: "role",
      label: "Роль",
      type: "select",
      variants: [
        { value: "Admin", label: "Админ" },
        { value: "User", label: "Пользователь" },
      ],
    },
    {
      name: "organizationId",
      label: "Организация",
      type: "select",
      variants: organizations,
      onChange: (event) => {
        dispatch(
          getDepartments(event.target.value, (departments) => {
            if (departments) {
              setDepartments(departments);
            }
          })
        );
        dispatch(
          getPositions(event.target.value, (positions) => {
            if (positions) {
              setPositions(positions);
            }
          })
        );
      },
    },
  ];
  const handleSubmit = (values) => {
    setLoading(true);
    values.registrationId = registrationId;
    values.accessToCreateConferences = values.role === "Admin";
    dispatch(
      createUserAccount(values, (success) => {
        setLoading(false);
        if (success) {
          handleClose();
        }
      })
    );
  };
  return (
    <Dialog
      open={userRequestDialog.open}
      onClose={handleClose}
      scroll="body"
      classes={{ paper: classes.content }}
      style={{ minWidth: 330 }}
    >
      {typeof registrationStatuses[registrationId] !== "undefined" &&
      !isLoading ? (
        <DialogContent dividers={false}>
          <div
            className="flex_box"
            style={{ justifyContent: "space-between", marginBottom: 10 }}
          >
            <Typography variant="h6">
              {Boolean(user.userName) ? user.userName : "Не известно"}
            </Typography>
            <img
              style={{ cursor: "pointer" }}
              src={close}
              alt="close_icon"
              onClick={handleClose}
            />
          </div>
          <Typography
            variant="body1"
            style={{ marginBottom: 10 }}
          >{`Регистрация: ${
            registrationStatuses[registrationId].registrationComplete
              ? "Завершена"
              : "Не завершена"
          }`}</Typography>
          <Typography variant="body1" style={{ marginBottom: 30 }}>{`Email: ${
            registrationStatuses[registrationId].emailConfirmed
              ? "Подтвержден"
              : "Не подтвержден"
          }`}</Typography>
          {Boolean(user.userInn) && (
            <div
              className="flex_box"
              style={{ justifyContent: "flex-start", margin: "10px 0px" }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 600, marginRight: 12, width: "50px" }}
              >
                ИНН
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 400 }}>
                {user.userInn}
              </Typography>
            </div>
          )}
          {Boolean(user.email) && (
            <div
              className="flex_box"
              style={{ justifyContent: "flex-start", margin: "10px 0px" }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 600, marginRight: 12, width: "50px" }}
              >
                Email
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 400 }}>
                {user.email}
              </Typography>
            </div>
          )}
          {Boolean(user.isMale) && (
            <div
              className="flex_box"
              style={{ justifyContent: "flex-start", margin: "10px 0px" }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 600, marginRight: 12, width: "50px" }}
              >
                Пол
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 400 }}>
                {user.isMale ? "Мужчина" : "Женщина"}
              </Typography>
            </div>
          )}
          <GetFormParser
            registrationId={registrationId}
            fields={fields}
            onSubmit={handleSubmit}
            initialValues={{
              role: "",
              organizationId: "",
              departmentId: "",
              positionId: "",
            }}
            validationSchema={Yup.object({
              role: Yup.string().required("Выберите из списка"),
              organizationId: Yup.string().required("Выберите из списка"),
              departmentId: Yup.string(),
              positionId: Yup.string().required("Выберите из списка"),
            })}
            action={
              <div className="flex_box" style={{ width: "100%" }}>
                <PrimaryButton
                  type="submit"
                  style={{ width: sm ? "100%" : 200 }}
                >
                  Сохранить
                </PrimaryButton>
              </div>
            }
            enableReinitialize
          />
        </DialogContent>
      ) : isLoading ? (
        <LoadingIndicator primary />
      ) : (
        "Данные не найдены"
      )}
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 594,
    "& .MuiDialogContent-root": {
      padding: "24px 26px",
    },
  },
}));
