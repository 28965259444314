import React from "react";
import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function TabsComponent({ value, setValue, buttons }) {
  const classes = useStyles();
  return (
    <Tabs
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      className={classes.tabs}>
      {buttons.map((text, index) => (
        <Tab key={text + index} label={text} value={index} />
      ))}
    </Tabs>
  );
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    maxHeight: 40,
    "& .MuiTab-root": {
      minWidth: 50,
      minHeight: 30,
      padding: "2px 0px",
      marginRight: 32,
      fontSize: 16,
      fontWeight: 600,
      color: "#757575",
      textAlign: "start",
      alignItems: "flex-start",
    },
    "& .Mui-selected": {
      color: "#1058FB !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#1058FB",
      height: 3,
      bottom: 15,
    },
  },
}));
