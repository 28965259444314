import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  Typography,
  Checkbox,
  Button,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import close from "assets/icons/Close.svg";
import { TextInput } from "shared/TextInput";
import { PrimaryButton } from "shared/Buttons";
import plusIcon from "assets/icons/bx-plus.svg";
import { ValidatedTextInput } from "shared/TextInput";
import LoadingIndicator from "shared/LoadingIndicator";
import { createStoreData, updateStoreData } from "store";
import { deleteTestingQuest } from "api/DELETE_requests";
import trashIcon from "assets/icons/bxs-trash-alt-filled.svg";
import { createOrUpdateTestingQuest, getTestingQuests } from "api";

export default function AddTestingQuestDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    addTestingQuestDialog: { open, testingId, testingQuest, questNumber },
  } = useSelector((store) => store);
  const [answers, setAnswer] = useState([
    { answer: "", isRightAnswer: true },
    { answer: "", isRightAnswer: false },
  ]);
  const [isLoading, setLoading] = useState(false);
  let isItUpdateMode = Boolean(Object.keys(testingQuest).length);

  React.useEffect(() => {
    if (isItUpdateMode && open) {
      setAnswer(testingQuest.answers);
    }
  }, [testingQuest, open, isItUpdateMode]);

  const handleSubmit = (values) => {
    let data = { ...values, answers, testingId, questNumber },
      emptyAnswers = [],
      haveRightAnswer = false;
    answers.forEach((item, itemIndex) => {
      if (!Boolean(item.answer)) {
        emptyAnswers.push(itemIndex + 1);
      }
      if (!haveRightAnswer && item.isRightAnswer) {
        haveRightAnswer = true;
      }
    });

    if (isItUpdateMode) {
      data.testingQuestId = testingQuest.testingQuestId;
    }
    if (Boolean(emptyAnswers.length)) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: `Вариант ответа ${emptyAnswers[0]} пустой`,
          },
        })
      );
      return;
    }
    if (!haveRightAnswer) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Минимум один ответ должен быть правильным",
          },
        })
      );
      return;
    }
    setLoading(true);
    dispatch(
      createOrUpdateTestingQuest(data, (success) => {
        setLoading(false);
        if (success) {
          dispatch(getTestingQuests(testingId));
          refreshState();
        }
      })
    );
  };
  const refreshState = () => {
    setAnswer([
      { answer: "", isRightAnswer: true },
      { answer: "", isRightAnswer: false },
    ]);
    dispatch(
      updateStoreData({
        key: "addTestingQuestDialog",
        data: {
          open: false,
          initialValues: { questNumber: "", quest: "" },
          testingId: "",
          testingQuest: {},
        },
      })
    );
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.paper }}>
      <DialogContent style={{ width: 600 }}>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 20 }}>
          <Typography variant="h6">
            {isItUpdateMode ? "Редактирование вопроса" : "Добавление вопроса"}
          </Typography>
          <img
            src={close}
            alt="close_icon"
            onClick={refreshState}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            quest: Boolean(testingQuest) ? testingQuest.quest : "",
          }}
          validationSchema={Yup.object({
            quest: Yup.string().required("Заполните поле"),
          })}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Typography variant="body2" className={classes.label}>
                Напишите текст вопроса
              </Typography>
              <ValidatedTextInput
                name="quest"
                value={formik.values["quest"]}
                onChange={formik.handleChange}
              />
              {answers.map((item, index) => (
                <div key={index}>
                  <Typography variant="body2" className={classes.label}>
                    {`Вариант ответа ${index + 1}`}
                  </Typography>
                  <div className="flex_box">
                    <TextInput
                      value={item.answer}
                      onChange={(event) => {
                        setAnswer((state) =>
                          state.map((itemAnswer, itemIndex) =>
                            itemIndex === index
                              ? {
                                  answer: event.target.value,
                                  isRightAnswer: itemAnswer.isRightAnswer,
                                }
                              : itemAnswer
                          )
                        );
                      }}
                      fullWidth
                    />
                    {(isItUpdateMode || (!isItUpdateMode && index > 1)) && (
                      <IconButton
                        onClick={() =>
                          setAnswer((state) =>
                            state.filter(
                              (_, answerIndex) => answerIndex !== index
                            )
                          )
                        }>
                        <img src={trashIcon} alt="trash icon" />
                      </IconButton>
                    )}
                  </div>
                  <FormControlLabel
                    style={{ cursor: "default" }}
                    checked={item.isRightAnswer}
                    onChange={(event, checked) =>
                      setAnswer((state) =>
                        state.map((itemAnswer) =>
                          itemAnswer.answer === item.answer
                            ? {
                                answer: itemAnswer.answer,
                                isRightAnswer: checked,
                              }
                            : itemAnswer
                        )
                      )
                    }
                    control={
                      <Checkbox style={{ cursor: "default" }} disableRipple />
                    }
                    label="Правильный ответ"
                  />
                </div>
              ))}
              <Button
                size="small"
                type="button"
                variant="outlined"
                onClick={() =>
                  setAnswer((state) => {
                    let newState = [...state];
                    newState.push({ answer: "", isRightAnswer: false });
                    return newState;
                  })
                }
                startIcon={<img src={plusIcon} alt="plus icon" />}
                style={{ marginTop: 24, marginBottom: 32 }}>
                Добавить вариант ответа
              </Button>
              <div className="flex_box">
                {isItUpdateMode && (
                  <Button
                    variant="delete"
                    style={{ width: 174, marginRight: 24 }}
                    onClick={() => {
                      dispatch(
                        createStoreData({
                          confirmRemoveDialog: {
                            open: true,
                            confirm: () =>
                              dispatch(
                                deleteTestingQuest(
                                  testingQuest.testingQuestId,
                                  (success) => {
                                    if (success) {
                                      dispatch(getTestingQuests(testingId));
                                      refreshState();
                                    }
                                  }
                                )
                              ),
                          },
                        })
                      );
                    }}>
                    Удалить вопрос
                  </Button>
                )}
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  style={{ width: 174 }}>
                  {!isLoading ? "Сохранить" : <LoadingIndicator small />}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 620,
  },
  label: {
    fontWeight: "600 !important",
    color: "#000000",
    marginTop: "20px !important",
    marginBottom: "8px !important",
  },
  addPillowBtn: {
    "&.MuiButton-root": {
      backgroundColor: "#EFEFEF",
      borderRadius: 25,
      fontSize: 14,
      fontWeight: 700,
      color: "#1058FB",
      boxShadow: "none",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
}));
