import React, { useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import CoursePaper from "components/CoursePaper";
import AddNewCourseDialog from "components/AddNewCourseDialog";
import { useSelector } from "react-redux";
import { PrimaryButton } from "shared/Buttons";

export default function Admin() {
  const { user, coursesList } = useSelector((store) => store);
  const [courseDialog, setCourseDialog] = useState();
  const [currentCourse, setCurrentCourse] = useState(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ width: "100%", padding: sm ? 30 : "50px 160px" }}>
      <AddNewCourseDialog
        open={courseDialog}
        handleClose={() => setCourseDialog(false)}
        course={currentCourse}
      />
      <div
        className="flex_box"
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginBottom: 35,
        }}>
        <Typography variant="h6">Курсы</Typography>
        {user.isAdmin && (
          <PrimaryButton size="small" onClick={() => setCourseDialog(true)}>
            Добавить курс
          </PrimaryButton>
        )}
      </div>
      {Boolean(coursesList.length) ? (
        coursesList.map((course) => (
          <CoursePaper
            key={course.learningCourseId}
            course={course}
            updateHandler={() => {
              setCurrentCourse(course);
              setCourseDialog(true);
            }}
          />
        ))
      ) : (
        <Typography variant="body1" style={{ color: "red" }}>
          Не найдено
        </Typography>
      )}
    </div>
  );
}
