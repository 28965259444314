import React, { useState } from "react";
import {
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";

import TabsComponent from "components/TabsComponent";
import CoursePaper from "components/CoursePaper";

export default function PersonalArea() {
  const {
    subscribedCourses,
    sections,
    courseStatuses,
  } = useSelector((store) => store);
  const [tab, setTab] = useState(0);
  const theme = useTheme();
  const sm = useMediaQuery(
    theme.breakpoints.down("sm")
  );

  return (
    <Container style={{ marginBottom: 20 }}>
      <Typography
        variant="h6"
        style={{
          fontWeight: "bold",
          margin: sm ? "20px 0px" : "40px 0px",
        }}
      >
        Мое обучение
      </Typography>
      <TabsComponent
        value={tab}
        setValue={setTab}
        buttons={[
          "Все курсы",
          "В процессе",
          "Завершенные",
        ]}
      />
      <div
        style={{ width: "100%", height: 22 }}
      />
      {subscribedCourses
        .filter((course) => {
          let statusesLength =
              courseStatuses[
                course.learningCourseId
              ].completedCourseSectionIds.length,
            sectionsLength =
              sections[course.learningCourseId]
                ?.courseSections.length,
            isPassed =
              Boolean(statusesLength) &&
              Boolean(sectionsLength) &&
              statusesLength === sectionsLength,
            isInProgress =
              Boolean(statusesLength) &&
              Boolean(sectionsLength) &&
              statusesLength !== sectionsLength;
          return tab === 0
            ? true
            : tab === 1
            ? isInProgress
            : isPassed;
        })
        .map((course) => (
          <CoursePaper
            key={course.learningCourseId}
            course={course}
            showProgress={tab === 1}
          />
        ))}
    </Container>
  );
}
