import React from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { createStoreData } from "store";
import close from "assets/icons/Close.svg";

export default function ConfirmRemoveDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    confirmRemoveDialog: { open, confirm },
  } = useSelector((store) => store);

  const closeHandler = () => {
    dispatch(
      createStoreData({
        confirmRemoveDialog: {
          open: false,
          confirmHandler: () => {},
        },
      })
    );
  };
  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogContent>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 20 }}>
          <Typography variant="h6">Удаление</Typography>
          <img
            src={close}
            alt="close_icon"
            onClick={closeHandler}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Typography variant="body1" style={{ margin: "24px 0px" }}>
          Вы уверены, что хотите удалить?
        </Typography>
        <div className="flex_box" style={{ gap: 20 }}>
          <Button
            variant="outlined"
            onClick={closeHandler}
            className={classes.cencellButton}>
            Отмена
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              confirm();
              closeHandler();
            }}
            className={classes.deleteButton}>
            Да, удалить
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    "&.MuiButton-root": {
      width: "calc(50% - 10px)",
      minHeight: 36,
      fontWeight: 700,
      color: "#ed1528",
      borderColor: "#ed1528",
    },
    "&.MuiButton-root:hover": {
      borderColor: "#ed1528",
    },
  },
  cencellButton: {
    "&.MuiButton-root": {
      width: "calc(50% - 10px)",
      maxHeight: 36,
    },
  },
}));
