import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Typography } from "@mui/material";
import * as Yup from "yup";

import close from "assets/icons/Close.svg";
import { PrimaryButton } from "shared/Buttons";
import GetFormParser from "./GetFormParser";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateSection, getBase64 } from "api";
import LoadingIndicator from "shared/LoadingIndicator";

export default function AddSectionDialog({ open = false, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentCourse } = useSelector((store) => store);
  const fileSelector = React.useRef();
  const [isLoading, setLoading] = useState(false);
  const [sectionCover, setSectionCover] = useState(null);

  const addNewCourse = async (values) => {
    if (sectionCover) {
      values.sectionImageBase64 = await getBase64(sectionCover);
    }
    setLoading(true);
    dispatch(
      createOrUpdateSection(
        {
          ...values,
          learningCourseId: currentCourse.learningCourseId,
          sectionSequenceNumber: parseInt(values.sectionSequenceNumber),
        },
        (success) => {
          setLoading(false);
          if (success) {
            refreshState();
          }
        }
      )
    );
  };

  const refreshState = () => {
    setSectionCover(null);
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.content }}
      scroll="body">
      <DialogContent dividers={false}>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 20 }}>
          <Typography variant="h6">Добавление раздела</Typography>
          <img
            src={close}
            alt="close_icon"
            onClick={refreshState}
            style={{ cursor: "pointer" }}
          />
        </div>
        {isLoading ? (
          <div className="flex_box" style={{ minHeight: 397 }}>
            <LoadingIndicator primary />
          </div>
        ) : (
          <GetFormParser
            fields={fields}
            initialValues={{
              sectionName: "",
              sectionSequenceNumber: "",
            }}
            onSubmit={addNewCourse}
            validationSchema={validation}
            action={
              <>
                <div className="flex_box" style={{ marginTop: 30 }}>
                  <PrimaryButton
                    variant="contained"
                    type="submit"
                    style={{ width: 300 }}
                    fullWidth>
                    Создать раздел
                  </PrimaryButton>
                </div>
              </>
            }
          />
        )}
        <input
          hidden
          ref={fileSelector}
          type="file"
          name="CourserImageBase64"
          onChange={(event) => {
            setSectionCover(event.target.files[0]);
          }}
          except=".png, .jpeg, .jpg"
        />
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 796,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      width: "100%",
    },
  },
  subtitle: {
    fontWeight: "600 !important",
    color: "#000000",
  },
  fileInput: {
    "& .Mui-disabled": { cursor: "pointer" },
    "& input::placeholder": {
      opacity: 1,
    },
  },
}));

const fields = [
  {
    name: "sectionName",
    type: "text",
    label: "Название раздела",
    placeholder: "Введите название",
  },
  {
    name: "sectionSequenceNumber",
    type: "text",
    label: "Порядковый номер",
    placeholder: "Введите номер",
  },
];
const validation = Yup.object({
  sectionName: Yup.string().required("Заполните поле"),
  sectionSequenceNumber: Yup.string().required("Заполните поле"),
});
