import React, { useState } from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import close from "assets/icons/Close.svg";
import { ValidatedTextInput } from "shared/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateTesting, getSectionTests, getTestingQuests } from "api";
import { PrimaryButton } from "shared/Buttons";
import LoadingIndicator from "shared/LoadingIndicator";
import { createStoreData } from "store";
import { makeStyles } from "@mui/styles";

export default function AddTestingDialog({
  open = false,
  handleClose,
  initialValues,
  courseSection,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { quests } = useSelector((store) => store);
  const [isLoading, setLoading] = useState(false);

  React.useEffect(() => {
    let testingId = initialValues.testingId;
    if (Boolean(testingId) && !Boolean(quests[testingId])) {
      dispatch(getTestingQuests(testingId));
    }
  }, [dispatch, initialValues, quests]);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(
      createOrUpdateTesting(
        {
          ...values,
          courseSectionId: courseSection.courseSectionId,
          testingSequenceNumber: 0,
        },
        (testingId) => {
          setLoading(false);
          if (testingId) {
            dispatch(getSectionTests(courseSection.courseSectionId));
            if (!Boolean(initialValues.testingId)) {
              dispatch(
                createStoreData({
                  alert: {
                    severity: "success",
                    message: "Пожалуйста добавьте вопрос для тестирования",
                  },
                })
              );
            }
            handleClose();
          }
        }
      )
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}>
      <DialogContent>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 20 }}>
          <Typography variant="h6">{`${
            Boolean(initialValues.testingId) ? "Редактирование" : "Создание"
          } тестирования`}</Typography>
          <img
            src={close}
            alt="close_icon"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={Yup.object({
            countAttempts: Yup.string().required("Заполните поле"),
            minimumRightAnswers: Yup.string().required("Заполните поле"),
            testingTime: Yup.string().required("Заполните поле"),
          })}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Typography variant="body2" className={classes.label}>
                Количество попыток для прохождения
              </Typography>
              <ValidatedTextInput
                type="number"
                name="countAttempts"
                value={formik.values["countAttempts"]}
                onChange={formik.handleChange}
              />
              <Typography variant="body2" className={classes.label}>
                Минимальное количество правильных ответов
              </Typography>
              <ValidatedTextInput
                type="number"
                name="minimumRightAnswers"
                value={formik.values["minimumRightAnswers"]}
                onChange={formik.handleChange}
              />
              <Typography variant="body2" className={classes.label}>
                Время на прохождение теста в минутах
              </Typography>
              <ValidatedTextInput
                type="number"
                name="testingTime"
                value={formik.values["testingTime"]}
                onChange={formik.handleChange}
              />
              <div className="flex_box">
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  style={{ width: 200, marginTop: 25 }}>
                  {!isLoading ? (
                    Boolean(initialValues.testingId) ? (
                      "Сохранить"
                    ) : (
                      "Создать"
                    )
                  ) : (
                    <LoadingIndicator small />
                  )}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 620,
  },
  label: {
    fontWeight: "600 !important",
    color: "#000000",
    marginTop: "32px !important",
    marginBottom: "8px !important",
  },
}));
