import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { nanoid } from "nanoid";
import * as Yup from "yup";

import crossIcon from "assets/icons/Close.svg";
import { makeStyles } from "@mui/styles";
import { ValidatedTextInput } from "shared/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateSection } from "api";
import { createStoreData } from "store";
import LoadingIndicator from "shared/LoadingIndicator";

const initialValuesConstant = {
  url: "",
  description: "",
};

export default function NewVideo({ open, handleClose, currentVideo }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentSection } = useSelector((store) => store);
  const [initialValues, setInitialValues] = useState(initialValuesConstant);
  const [isLoading, setLoading] = useState(false);
  let isItUpdateMode = typeof currentVideo !== "undefined";

  React.useEffect(() => {
    setInitialValues(isItUpdateMode ? currentVideo : initialValuesConstant);
  }, [isItUpdateMode, currentVideo]);

  const submitHandler = (values) => {
    if (!values.url.includes("watch?v=")) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Не подходящая ссылка. Вставьте из адресной строки видео",
          },
        })
      );
      return;
    }
    let newData = Boolean(currentSection.data)
        ? JSON.parse(currentSection.data)
        : {},
      videos = Boolean(newData.videos) ? newData.videos : [];

    if (isItUpdateMode) {
      let newVideos = videos.map((item) => {
        if (item.id === currentVideo.id) {
          values.id = currentVideo.id;
          return values;
        }
        return item;
      });
      videos = newVideos;
    } else {
      values.id = nanoid();
      videos.push(values);
    }
    setLoading(true);
    newData.videos = videos;
    currentSection.data = JSON.stringify(newData);

    dispatch(
      createOrUpdateSection(currentSection, (sections) => {
        setLoading(false);
        if (sections) {
          let matchedSection = sections
            ? sections.courseSections.filter(
                (item) =>
                  item.courseSectionId ===
                  parseInt(currentSection.courseSectionId)
              )
            : [];
          if (Boolean(matchedSection.length)) {
            dispatch(
              createStoreData({
                currentSection: matchedSection[0],
                alert: { severity: "success", message: "Успешно" },
              })
            );
            handleClose();
          }
        }
      })
    );
  };
  const closeHandler = () => {
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      classes={{ paper: classes.paper }}>
      <DialogContent>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 24 }}>
          <Typography variant="subtitle1">
            {isItUpdateMode ? "Обновление видео" : "Добавление видео"}
          </Typography>
          <IconButton onClick={closeHandler}>
            <img src={crossIcon} alt="cross" />
          </IconButton>
        </div>
        <Formik
          onSubmit={submitHandler}
          initialValues={initialValues}
          validationSchema={Yup.object({
            url: Yup.string().required("Поле не заполнена"),
            description: Yup.string().required("Поле не заполнена"),
          })}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Typography variant="body2" className={classes.label}>
                Youtube-ссылка на видео *
              </Typography>
              <ValidatedTextInput name="url" placeholder="Вставьте ссылку" />
              <Typography
                variant="body2"
                className={classes.label}
                style={{ marginTop: 20 }}>
                Описание видео *
              </Typography>
              <ValidatedTextInput
                name="description"
                rows={4}
                multiline
                placeholder="Введите описание видео"
              />
              <div className="flex_box" style={{ gap: 20, marginTop: 24 }}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={isLoading}
                  style={{ width: 160 }}
                  onClick={closeHandler}>
                  Отмена
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  style={{ width: 160 }}>
                  {isLoading ? (
                    <LoadingIndicator small />
                  ) : isItUpdateMode ? (
                    "Сохранить"
                  ) : (
                    "Добавить"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: "start",
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    color: "#FFF",
    marginBottom: "8px !important",
  },
  paper: {
    minWidth: 620,
  },
}));
