import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import pencil from "assets/icons/pencil.svg";
import trash from "assets/icons/trash.svg";
import close from "assets/icons/Close.svg";
import TabsComponent from "./TabsComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  deleteRegistrationRequest,
  deleteUser,
  getOrganizations,
  getUsersList,
  searchRegisteredUsers,
} from "api";
import LoadingIndicator from "shared/LoadingIndicator";
import { createStoreData } from "store";

export default function AdministrationDialog({ open = false, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, usersList, registeredUsers, organizations } = useSelector(
    (store) => store
  );
  const [tab, setTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (open) {
      if (usersList !== "Not found" && !Boolean(usersList.users.length)) {
        setLoading(true);
        dispatch(getUsersList({}, () => setLoading(false)));
      }
      if (registeredUsers !== "Not found" && !Boolean(registeredUsers.length)) {
        dispatch(searchRegisteredUsers());
      }
      if (organizations !== "Not found" && !Boolean(organizations.length)) {
        dispatch(getOrganizations());
      }
    }
  }, [dispatch, usersList, user, registeredUsers, organizations, open]);

  const refreshState = () => {
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.content }}
      scroll="body">
      <DialogContent dividers={false}>
        <div
          className="flex_box"
          style={{
            justifyContent: "space-between",
            marginBottom: sm ? 20 : 30,
          }}>
          <Typography variant="h6">Администрирование</Typography>
          <img
            style={{ cursor: "pointer" }}
            src={close}
            alt="close_icon"
            onClick={refreshState}
          />
        </div>
        <TabsComponent
          value={tab}
          setValue={setTab}
          buttons={["Пользователи", `Заявки`]}
        />
        {tab === 0 ? (
          usersList !== "Not found" && Boolean(usersList.users.length) ? (
            <div
              style={{
                border: "0.5px solid #DDDDDD",
                marginTop: sm ? 20 : 30,
              }}>
              {usersList.users.map((user, userIndex, array) => (
                <div
                  className="flex_box"
                  style={{
                    width: "100%",
                    padding: 20,
                    justifyContent: "space-between",
                    borderBottom:
                      userIndex + 1 === array.length
                        ? "none"
                        : "1px solid #DDDDDD",
                  }}
                  key={user.userId}>
                  <div>
                    <Typography variant="body1">{user.userName}</Typography>
                    <Typography variant="body2" style={{ color: "#757575" }}>
                      {user.positionName}
                    </Typography>
                  </div>
                  <div className="flex_box" style={{ maxWidth: 320 }}>
                    <IconButton
                      onClick={() => {
                        dispatch(
                          createStoreData({
                            updateUserDialog: true,
                            currentAccount: user,
                          })
                        );
                      }}>
                      <img src={pencil} alt="pencil" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          createStoreData({
                            confirmRemoveDialog: {
                              open: true,
                              confirm: () => dispatch(deleteUser(user.userId)),
                            },
                          })
                        )
                      }>
                      <img src={trash} alt="trash" />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          ) : isLoading ? (
            <LoadingIndicator primary />
          ) : (
            <div className="flex_box">
              <Typography variant="body1" style={{ color: "red" }}>
                Не найдено
              </Typography>
            </div>
          )
        ) : null}

        {tab === 1 ? (
          registeredUsers !== "Not found" && Boolean(registeredUsers.length) ? (
            <div style={{ border: "0.5px solid #DDDDDD", marginTop: 30 }}>
              {registeredUsers.map((user, userIndex, array) => (
                <div
                  className="flex_box"
                  style={{
                    width: "100%",
                    padding: 20,
                    justifyContent: "space-between",
                    borderBottom:
                      userIndex + 1 === array.length
                        ? "none"
                        : "1px solid #DDDDDD",
                  }}
                  key={userIndex}>
                  <Typography variant="body1">{user.userName}</Typography>
                  <div className="flex_box" style={{ maxWidth: 320 }}>
                    <IconButton
                      onClick={() => {
                        dispatch(
                          createStoreData({
                            userRequestDialog: {
                              open: true,
                              user: user,
                            },
                          })
                        );
                      }}>
                      <img src={pencil} alt="pencil" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          createStoreData({
                            confirmRemoveDialog: {
                              open: true,
                              confirm: () =>
                                dispatch(
                                  deleteRegistrationRequest(user.userId)
                                ),
                            },
                          })
                        )
                      }>
                      <img src={trash} alt="trash" />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          ) : isLoading ? (
            <LoadingIndicator primary />
          ) : (
            <div className="flex_box">
              <Typography variant="body1" style={{ color: "red" }}>
                Не найдено
              </Typography>
            </div>
          )
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 796,
    minHeight: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: "24px 26px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 16px",
      },
    },
  },
}));
