import { makeRequest } from "api";
import { createStoreData } from "store";

export const deleteCourse = (learningCourseId, callback) => (dispatch) => {
  makeRequest(
    `/LearningCourse/DeleteCourse/{sessionId}/${learningCourseId}`,
    dispatch,
    "delete"
  ).then((json) => {
    callback(Boolean(json));
    dispatch(
      createStoreData({
        alert: {
          severity: json ? "success" : "error",
          message: json ? "Курс удален" : "Не удалось удалить курс",
        },
      })
    );
  });
};

export const deleteCourseSection =
  (courseSectionId, callback) => (dispatch) => {
    makeRequest(
      `/LearningCourse/DeleteCourseSection/{sessionId}/${courseSectionId}`,
      dispatch,
      "delete"
    ).then((json) => {
      callback(Boolean(json));
      dispatch(
        createStoreData({
          alert: {
            severity: json ? "success" : "error",
            message: json ? "Раздел удален" : "Не удалось удалить раздел",
          },
        })
      );
    });
  };

export const deleteSectionTest = (testingId, callback) => (dispatch) => {
  makeRequest(
    `/LearningCourse/DeleteTesting/{sessionId}/${testingId}`,
    dispatch,
    "delete"
  ).then((json) => {
    callback(Boolean(json));
    dispatch(
      createStoreData({
        alert: {
          severity: json ? "success" : "error",
          message: json ? "Тест удален" : "Не удалось удалить тест",
        },
      })
    );
  });
};
export const deleteTestingQuest = (testingQuestId, callback) => (dispatch) => {
  makeRequest(
    `/LearningCourse/DeleteTestingQuest/{sessionId}/${testingQuestId}`,
    dispatch,
    "delete"
  ).then((json) => {
    if (json) {
      callback(true);
    }
    callback(false);
    dispatch(
      createStoreData({
        alert: {
          severity: json ? "success" : "error",
          message: json ? "Успешно" : "Не удалось удалить",
        },
      })
    );
  });
};
