import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
} from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";

import selfExaminationActive from "assets/icons/bx-list-check.svg";
import literatureActive from "assets/icons/bxs-book-bookmark.svg";
import presentationActive from "assets/icons/bxs-file.svg";
import practicalExerciseActive from "assets/icons/bxs-file-export.svg";
import videocontentActive from "assets/icons/bxs-videos.svg";
import testingActive from "assets/icons/bx-task.svg";
import selfExaminationInactive from "assets/icons/Grey_bx-list-check.svg";
import literatureInactive from "assets/icons/Grey_bxs-book-bookmark.svg";
import presentationInactive from "assets/icons/Grey_bxs-file.svg";
import practicalExerciseInactive from "assets/icons/Grey_bxs-file-export.svg";
import videocontentInactive from "assets/icons/Grey_bxs-videos.svg";
import testingInactive from "assets/icons/Grey_bx-task.svg";

const getIcon = {
  "Вопросы для самопроверки": {
    true: selfExaminationActive,
    false: selfExaminationInactive,
  },
  Литература: {
    true: literatureActive,
    false: literatureInactive,
  },
  Презентация: {
    true: presentationActive,
    false: presentationInactive,
  },
  "Практические упражнения": {
    true: practicalExerciseActive,
    false: practicalExerciseInactive,
  },
  Видеоматериалы: {
    true: videocontentActive,
    false: videocontentInactive,
  },
  Тестирование: {
    true: testingActive,
    false: testingInactive,
  },
};
const getStepPriority = {
  Презентация: 1,
  Видеоматериалы: 2,
  Литература: 3,
  "Вопросы для самопроверки": 4,
  "Практические упражнения": 5,
  Тестирование: 6,
};

const CustomizedStepConnector = styled(StepConnector)(({ theme }) => ({
  top: "25px !important",
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1058FB",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1058FB",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: 2,
    borderColor: "#D8D8D8",
  },
}));

const CustomizedStepIcon = ({ active, completed, index, clickable, label }) => (
  <div
    style={{
      display: "flex",
      width: 50,
      height: 50,
      backgroundColor: active || completed ? "#CFDEFE" : "#D8D8D8",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      zIndex: 10,
      cursor: clickable ? "pointer" : "default",
    }}>
    <img src={getIcon[label][active || completed]} alt="" />
  </div>
);

const CustomizedStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.labelContainer} .${stepLabelClasses.label}.${stepLabelClasses.active}`]:
    {
      color: "#1058FB",
    },
  [`& .${stepLabelClasses.labelContainer} .${stepLabelClasses.label}.${stepLabelClasses.completed}`]:
    {
      color: "#1058FB",
    },
  [`& .${stepLabelClasses.labelContainer} .${stepLabelClasses.label}`]: {
    color: "#333333",
    marginTop: 8,
  },
}));

export default function StepperCustomized({
  steps,
  activeStep,
  onChangeActiveStep,
  ...props
}) {
  return (
    <Stepper
      {...props}
      activeStep={activeStep}
      connector={<CustomizedStepConnector />}
      alternativeLabel>
      {steps
        .sort((a, b) => getStepPriority[a] - getStepPriority[b])
        .map((label, index) => (
          <Step
            key={label}
            style={{
              cursor: Boolean(onChangeActiveStep) ? "pointer" : "default",
            }}
            onClick={
              Boolean(onChangeActiveStep)
                ? () => onChangeActiveStep(index)
                : undefined
            }>
            <CustomizedStepLabel
              StepIconComponent={CustomizedStepIcon}
              StepIconProps={{
                index,
                label,
                clickable: Boolean(onChangeActiveStep),
              }}>
              {label}
            </CustomizedStepLabel>
          </Step>
        ))}
    </Stepper>
  );
}
