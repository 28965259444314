import React, { useState } from "react";
import {
  AppBar,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import EmblemOfKyrgyzstan from "assets/icons/Emblem_of_Kyrgyzstan.svg";
import unicefLogo from "assets/icons/UNICEF_Logo.svg";
import unicefLogoSecondary from "assets/icons/UNICEF_Logo_secondary.svg";
import profileLogo from "assets/icons/profile.svg";
import menuIcon from "assets/icons/Menu.svg";
import { HeaderThemeButton } from "./Buttons";
import AuthorizationDialog from "components/AuthorizationDialog";
import AdministrationDialog from "components/AdministrationDialog";
import UserRequestDialog from "components/UserRequestDialog";
import { logout } from "api";
import { makeStyles } from "@mui/styles";
import { createStoreData } from "store";
import UpdateUserDialog from "components/UpdateUserDialog";
import ConfirmRemoveDialog from "components/ConfirmRemoveDialog";

export default function Header() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, authorizationDialog } = useSelector((store) => store);
  const [administrationDialog, setAdministrationDialog] = useState(false);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  let isRegistrationPage = window.location.pathname === "/registration",
    isCoursePage = window.location.pathname.split("/")[1] === "aboute-course",
    isTransparent =
      isRegistrationPage ||
      !Boolean(window.location.pathname.split("/")[1]) ||
      isCoursePage;
  return (
    <>
      <AuthorizationDialog
        open={authorizationDialog}
        handleClose={(state) =>
          dispatch(createStoreData({ authorizationDialog: state }))
        }
      />
      <AdministrationDialog
        open={administrationDialog}
        handleClose={(state) => setAdministrationDialog(state)}
      />
      <ConfirmRemoveDialog />
      <UserRequestDialog />
      <UpdateUserDialog />
      <AppBar
        elevation={0}
        position="static"
        style={{
          backgroundColor: isTransparent ? "rgba(0, 0, 0, 0.3)" : "#1058FB",
        }}>
        <Toolbar
          style={{
            padding: lg ? "9px 25px" : "18px 70px",
            justifyContent: "space-between",
          }}>
          <div className="flex_box">
            {lg ? (
              <IconButton
                onClick={() => navigate("/")}
                style={{ marginRight: 20 }}>
                <img
                  src={EmblemOfKyrgyzstan}
                  style={{ width: sm ? 32 : 48 }}
                  alt="Emblem_of_Kyrgyzstan"
                />
              </IconButton>
            ) : (
              <div className="flex_box" style={{ marginRight: 60 }}>
                <IconButton onClick={() => navigate("/")}>
                  <img src={EmblemOfKyrgyzstan} alt="Emblem_of_Kyrgyzstan" />
                </IconButton>
                <Typography
                  variant="caption"
                  style={{
                    maxWidth: 240,
                    color: "#FFFFFF",
                    lineHeight: "124%",
                  }}>
                  Министерство труда, социального обеспечения и миграции
                  Кыргызской Республики
                </Typography>
              </div>
            )}
            <img
              style={{
                width: sm ? 32 : lg ? 50 : "auto",
              }}
              src={lg ? unicefLogoSecondary : unicefLogo}
              alt="unicef logo"
            />
          </div>
          <div className="flex_box">
            {Boolean(user.sessionId) ? (
              <PopupState variant="popover" popupId="profile-popover">
                {(popupState) => (
                  <div>
                    {sm ? (
                      <IconButton {...bindTrigger(popupState)}>
                        <img
                          style={{ width: 32 }}
                          src={menuIcon}
                          alt="menu icon"
                        />
                      </IconButton>
                    ) : (
                      <HeaderThemeButton
                        {...bindTrigger(popupState)}
                        startIcon={<img src={profileLogo} alt="" />}>
                        {user.userName}
                      </HeaderThemeButton>
                    )}
                    <Popover
                      {...bindPopover(popupState)}
                      classes={{ paper: classes.paper }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}>
                      {Boolean(user.isSuperAdmin) ? (
                        <HeaderThemeButton
                          className={classes.MenuButton}
                          onClick={() => {
                            setAdministrationDialog(true);
                          }}>
                          Администрирование
                        </HeaderThemeButton>
                      ) : (
                        <HeaderThemeButton
                          className={classes.MenuButton}
                          onClick={() => {
                            popupState.close();
                            return navigate(
                              Boolean(user.isAdmin) ||
                                Boolean(user.isSuperAdmin)
                                ? "/administration"
                                : "/personal"
                            );
                          }}>
                          Личный кабинет
                        </HeaderThemeButton>
                      )}
                      <HeaderThemeButton
                        className={classes.MenuButton}
                        onClick={() => {
                          popupState.close();
                          dispatch(logout());
                        }}>
                        Выход
                      </HeaderThemeButton>
                    </Popover>
                  </div>
                )}
              </PopupState>
            ) : (
              <HeaderThemeButton
                onClick={() =>
                  dispatch(createStoreData({ authorizationDialog: true }))
                }
                variant="outlined"
                style={{ marginLeft: 32 }}>
                Войти
              </HeaderThemeButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPopover-paper": {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
    },
  },
  MenuButton: {
    "&.MuiButton-root": { color: "#1058FB" },
  },
}));
