import React, { useState } from "react";
import {
  Alert,
  createTheme,
  CssBaseline,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import cookie_js from "cookie_js";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Main from "pages/Main";
import Header from "shared/Header";
import Footer from "shared/Footer";
import Registration from "pages/Registration";
import PersonalArea from "pages/PersonalArea";
import AbouteCourse from "pages/AbouteCourse";
import CourseSection from "pages/CourseSection";
import Admin from "pages/Admin";
import { createStoreData } from "store";
import "./App.css";
import { getUserProfile } from "api";

const CheckIsAuthorized = ({ children }) => {
  const { user } = useSelector((store) => store);

  return !!user.sessionId ? children : <Navigate to="/" />;
};

function App() {
  const dispatch = useDispatch();
  const { alert } = useSelector((store) => store);
  const [openAlert, setAlertOpen] = useState(false);

  React.useEffect(() => {
    let sessionId = cookie_js.get("kurs_sessionId");
    if (sessionId) {
      dispatch(getUserProfile(sessionId));
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (Boolean(alert.message)) {
      setAlertOpen(true);
    }
  }, [alert]);

  const closeSnackbar = () => {
    setAlertOpen(false);
    setTimeout(() => {
      dispatch(() =>
        createStoreData({ alert: { message: "", severity: "success" } })
      );
    }, 1000);
  };

  return (
    <div className="App " style={{ width: "100%" }}>
      <BrowserRouter>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />

          <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ minWidth: 300 }}>
            <Alert
              variant="filled"
              severity={alert.severity}
              onClose={closeSnackbar}>
              {alert.message}
            </Alert>
          </Snackbar>

          <div>
            <Header />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/registration" element={<Registration />} />
              <Route
                path="/personal"
                element={
                  <CheckIsAuthorized>
                    <PersonalArea />
                  </CheckIsAuthorized>
                }
              />
              <Route
                path="/administration"
                element={
                  <CheckIsAuthorized>
                    <Admin />
                  </CheckIsAuthorized>
                }
              />

              <Route
                path={"/aboute-course/:id"}
                element={
                  <CheckIsAuthorized>
                    <AbouteCourse />
                  </CheckIsAuthorized>
                }
              />
              <Route
                path={"/course-section/:courseId/:sectionId"}
                element={
                  <CheckIsAuthorized>
                    <CourseSection />
                  </CheckIsAuthorized>
                }
              />
            </Routes>
          </div>
          <Footer />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

const theme = createTheme();
const muiTheme = createTheme({
  typography: {
    h1: {
      fontFamily: "Inter",
      fontSize: 50,
      fontWeight: 700,
      lineHeight: "124%",
      [theme.breakpoints.down("sm")]: {
        fontSize: 34,
      },
    },
    h6: {
      fontFamily: "Inter",
      fontSize: 24,
      fontWeight: "bold",
      lineHeight: "124%",
    },
    subtitle1: {
      fontFamily: "Inter",
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: "120%",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    body1: {
      fontFamily: "Open Sans",
      lineHeight: "125%",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    body2: {
      fontFamily: "Inter",
      color: "#333333",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    caption: {
      fontFamily: "Open Sans",
      lineHeight: "124%",
    },
    button: {
      textTransform: "none",
      fontFamily: "Inter",
      lineHeight: "125%",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          minHeight: "calc(100vh - 240px)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 40,
          boxShadow: "none",
          borderRadius: 4,
        },
        outlined: {
          color: "#1058fb",
        },
      },
      variants: [
        {
          props: { variant: "delete" },
          style: {
            color: "#ed1528",
            border: "1px solid #EB5757",
          },
        },
        {
          props: { variant: "green" },
          style: {
            color: "#ffffff",
            backgroundColor: "#27AE60",
            "&:hover": { backgroundColor: "#27AE60" },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& textarea": {
            minHeight: "92px",
          },
        },
      },
    },
  },
});
