import React, { useState } from "react";
import { useSelector } from "react-redux";

import report from "assets/icons/report.svg";
import plusIcon from "assets/icons/bx-plus.svg";
import workplace from "assets/icons/workplace.svg";
import editIcon from "assets/icons/bxs-edit-alt.svg";
import rightArrowIcon from "assets/icons/bx-arrow-back.svg";
import NewSelfExaminationQuestionModal from "components/NewSelfExaminationQuestionModal";
import NewPracticalExerciseModal from "components/NewPracticalExerciseModal";
import { Button, Divider, Typography } from "@mui/material";
import { PrimaryButton } from "shared/Buttons";

export default function Questions({
  tab,
  data,
  timeleft,
  setTimeLeft,
  setDisabledBtn,
  setFinishBtnDisabled,
}) {
  const { user } = useSelector((store) => store);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [newQuestionDialog, setNewQuestionDialog] = useState(false);
  const [newExerciseDialog, setNewExerciseDialog] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  let isUser = !user.isAdmin && !user.isSuperAdmin,
    isSelfExamination = tab === "selfExamination",
    isPracticalExercises = tab === "practicalExercises",
    minutes = Math.floor((timeleft % 3600) / 60),
    seconds = Math.floor(timeleft % 60),
    questionsAmount = isSelfExamination
      ? Boolean(data.selfExaminationQuestions)
        ? data.selfExaminationQuestions.length
        : 0
      : isPracticalExercises
      ? Boolean(data.practicalExerciseQuestions)
        ? data.practicalExerciseQuestions.length
        : 0
      : 0;

  React.useEffect(() => {
    if (
      activeQuestion + 1 === questionsAmount &&
      minutes === 0 &&
      seconds === 0
    ) {
      setDisabledBtn(false);
      setFinishBtnDisabled(false);
    }
  }, [
    minutes,
    seconds,
    activeQuestion,
    setDisabledBtn,
    questionsAmount,
    setFinishBtnDisabled,
  ]);

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 27 }}>
        {isSelfExamination
          ? "Вопросы для самопроверки"
          : isPracticalExercises
          ? "Практические упражнения"
          : "Тип таба не известен"}
      </Typography>
      {Boolean(questionsAmount) ? (
        <>
          {isUser && (
            <Typography
              variant="body1"
              style={{ color: "#1058FB", marginTop: 27 }}>{`${
              isSelfExamination ? "Вопрос" : "Упражнение"
            } ${activeQuestion + 1}/${questionsAmount}`}</Typography>
          )}
          {data[
            isSelfExamination
              ? "selfExaminationQuestions"
              : "practicalExerciseQuestions"
          ].map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                {(activeQuestion === index || user.isAdmin) && (
                  <>
                    {user.isAdmin && (
                      <div
                        className="flex_box"
                        style={{
                          justifyContent: "space-between",
                        }}>
                        <Typography
                          variant="body1"
                          style={{ color: "#1058FB", fontWeight: 600 }}>{`${
                          isSelfExamination ? "Вопрос" : "Упражнение"
                        } ${index + 1}`}</Typography>
                        <Button
                          size="small"
                          variant="outlined"
                          style={{
                            width: 200,
                            minHeight: 32,
                          }}
                          onClick={() => {
                            setCurrentQuestion(item);
                            if (isSelfExamination) {
                              setNewQuestionDialog(true);
                            }
                            if (isPracticalExercises) {
                              setNewExerciseDialog(true);
                            }
                          }}
                          startIcon={
                            <img
                              src={editIcon}
                              style={{ width: 20, height: 20 }}
                              alt=""
                            />
                          }>
                          Редактировать
                        </Button>
                      </div>
                    )}

                    <Typography
                      variant="body1"
                      style={{
                        maxWidth: "calc(100% - 200px)",
                        marginTop: 11,
                        marginBottom: 22,
                      }}>
                      {item.question}
                    </Typography>
                    {isSelfExamination ? (
                      <div
                        style={{
                          padding: "12px 18px",
                          backgroundColor: "#F1F2F3",
                          borderRadius: 4,
                        }}>
                        {timeleft > 0 ? (
                          <Typography
                            variant="body1"
                            style={{
                              color: "#27AE60",
                              fontWeight: 600,
                            }}>
                            {`Экспертное мнение будет показано через ${
                              minutes || "00"
                            }:${
                              Boolean(seconds)
                                ? seconds < 10
                                  ? `0${seconds}`
                                  : seconds
                                : "00"
                            }`}
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 700,
                                color: "#2D9CDB",
                              }}>
                              Экспертное мнение
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: "#2D9CDB" }}>
                              {item.answer}
                            </Typography>
                          </>
                        )}
                      </div>
                    ) : user.isSuperAdmin ||
                      user.isAdmin ||
                      (isUser && showAnswer) ? (
                      <div
                        style={{
                          padding: "12px 18px",
                          backgroundColor: "#F1F2F3",
                          borderRadius: 4,
                        }}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 600,
                            color: "#2D9CDB",
                          }}>
                          Правильный ответ
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#2D9CDB" }}>
                          {item.answer}
                        </Typography>
                      </div>
                    ) : null}
                    {isPracticalExercises && isUser && !showAnswer && (
                      <PrimaryButton
                        onClick={() => {
                          setShowAnswer(true);
                        }}
                        style={{ width: 170 }}>
                        Показать ответ
                      </PrimaryButton>
                    )}
                    <Divider
                      style={{
                        backgroundColor: "#BDBDBD",
                        margin: "24px 0px",
                      }}
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
          {isUser && activeQuestion + 1 < questionsAmount && (
            <PrimaryButton
              onClick={() => {
                if (isSelfExamination) {
                  setTimeLeft(
                    data.selfExaminationQuestions[activeQuestion + 1]
                      .timeToReply * 60
                  );
                }
                setActiveQuestion((state) => state + 1);
                if (isPracticalExercises) {
                  setShowAnswer(false);
                }
              }}
              endIcon={<img src={rightArrowIcon} alt="" />}
              style={{ width: 330, marginTop: 30 }}
              disabled={(seconds > 0 || minutes > 0) && isUser}>
              Следующий вопрос
            </PrimaryButton>
          )}
        </>
      ) : (
        <div className="flex_box" style={{ minHeight: "calc(100vh - 500px)" }}>
          <span style={{ width: 350, textAlign: "center" }}>
            <img src={isSelfExamination ? workplace : report} alt="" />
            <Typography
              variant="subtitle1"
              style={{ color: "#828282", margin: "30px 0px" }}>
              Добавьте материалы в раздел
            </Typography>
            {user.isAdmin && (
              <Button
                type="button"
                variant="outlined"
                style={{ width: 200 }}
                onClick={() =>
                  isSelfExamination
                    ? setNewQuestionDialog(true)
                    : isPracticalExercises
                    ? setNewExerciseDialog(true)
                    : alert("Тип таба не известен")
                }>
                {`Добавить ${isSelfExamination ? "вопрос" : "упражнение"}`}
              </Button>
            )}
          </span>
        </div>
      )}
      {user.isAdmin && Boolean(questionsAmount) && (
        <PrimaryButton
          size="small"
          style={{
            backgroundColor: "transparent",
            color: "#1058FB",
            fontWeight: 400,
          }}
          onClick={() =>
            isSelfExamination
              ? setNewQuestionDialog(true)
              : isPracticalExercises
              ? setNewExerciseDialog(true)
              : alert("Тип таба не известен")
          }
          startIcon={
            <img src={plusIcon} style={{ width: 20, height: 20 }} alt="" />
          }>
          {`Добавить ${isSelfExamination ? "вопрос" : "упражнение"}`}
        </PrimaryButton>
      )}
      <NewSelfExaminationQuestionModal
        open={newQuestionDialog}
        closeHandler={() => {
          setNewQuestionDialog(false);
          setCurrentQuestion(undefined);
        }}
        currentQuestion={currentQuestion}
      />
      <NewPracticalExerciseModal
        open={newExerciseDialog}
        closeHandler={() => {
          setNewExerciseDialog(false);
          setCurrentQuestion(undefined);
        }}
        currentQuestion={currentQuestion}
      />
    </>
  );
}
