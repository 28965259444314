import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import EmblemOfKyrgyzstan from "assets/icons/Emblem_of_Kyrgyzstan.svg";

export default function Certificate() {
  const { user, currentCourse } = useSelector((store) => store);

  return (
    <div className="certificate_wrapper">
      <div className="flex_box" style={{ marginTop: 28 }}>
        <img className="logo" src={EmblemOfKyrgyzstan} alt="" />
      </div>
      <Typography
        style={{
          fontSize: 9,
          fontWeight: 600,
          textTransform: "uppercase",
          color: "#333333",
          textAlign: "center",
          marginTop: 10,
        }}>
        Министерство труда, социального обеспечения и миграции
        <br /> Кыргызской Республики
      </Typography>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 700,
          textTransform: "uppercase",
          color: "#004893",
          textAlign: "center",
          marginTop: 50,
        }}>
        СЕРТИФИКАТ ОБ ОКОНЧАНИИ УЧЕБНОГО КУРСА
      </Typography>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          textTransform: "uppercase",
          color: "#757575",
          textAlign: "center",
          marginTop: 23,
        }}>
        НАСТОЯЩИМ УДОСТОВЕРЯЕТСЯ, ЧТО
      </Typography>
      <Typography
        style={{
          fontSize: 40,
          fontWeight: 700,
          textTransform: "uppercase",
          color: "#004893",
          textAlign: "center",
          marginTop: 21,
        }}>
        {user.userName}
      </Typography>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          color: "#757575",
          textAlign: "center",
          marginTop: 23,
        }}>
        Завершио образовательный курс на тему “{currentCourse.courseName}”
      </Typography>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          color: "#333333",
          textAlign: "center",
          marginTop: 65,
        }}>
        Алыбаева Ж.А.
      </Typography>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          color: "#5998BB",
          textAlign: "center",
          marginTop: 6,
          marginBottom: 100,
        }}>
        Заместитель министра труда, социального
        <br /> обеспечения и миграции Кыргызской Республики
      </Typography>
      <div className="triangle top_left" />
      <div className="triangle top_right" />
      <div className="triangle bottom_right" />
      <div className="triangle bottom_left" />
    </div>
  );
}
