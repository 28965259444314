import React from "react";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import unicefLogo from "assets/icons/UNICEF_Logo.svg";
import EmblemOfKyrgyzstan from "assets/icons/Emblem_of_Kyrgyzstan.svg";
import unicefLogoSecondary from "assets/icons/UNICEF_Logo_secondary.svg";

export default function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <footer
      className="flex_box"
      style={{
        width: "100%",
        padding: sm ? "12px 16px" : lg ? "19px 35px" : "38px 70px",
        justifyContent: lg ? "flex-start" : "space-between",
      }}>
      {lg ? (
        <IconButton onClick={() => navigate("/")} style={{ marginRight: 12 }}>
          <img
            style={{ width: 32, height: 32 }}
            src={EmblemOfKyrgyzstan}
            alt="Emblem_of_Kyrgyzstan"
          />
        </IconButton>
      ) : (
        <div className="flex_box" style={{ marginRight: 60 }}>
          <IconButton onClick={() => navigate("/")}>
            <img src={EmblemOfKyrgyzstan} alt="Emblem_of_Kyrgyzstan" />
          </IconButton>
          <Typography
            variant="caption"
            style={{ maxWidth: 240, color: "#FFFFFF" }}>
            Министерство труда, социального обеспечения и миграции Кыргызской
            Республики
          </Typography>
        </div>
      )}
      <img
        src={lg ? unicefLogoSecondary : unicefLogo}
        style={{ width: sm ? 32 : lg ? 115 : "auto" }}
        alt="unicef logo"
      />
    </footer>
  );
}
