import React, { useState } from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";

import {
  getCourseSections,
  getCoursesList,
  getSectionTests,
  getTestingQuests,
  setCompleteCourseSection,
} from "api";
import NewLink from "components/NewLink";
import NewVideo from "components/NewVideo";
import PassTest from "components/PassTest";
import Questions from "components/Questions";
import { PrimaryButton } from "shared/Buttons";
import { EditTest } from "components/EditTest";
import plusIcon from "assets/icons/bx-plus.svg";
import NewDocument from "components/NewDocument";
import { VideoItem } from "components/VideoItem";
import literatureIcon from "assets/icons/books.svg";
import fileGrey from "assets/icons/bxs-file-grey.svg";
import LoadingIndicator from "shared/LoadingIndicator";
import {
  updateStoreData,
  createStoreData,
} from "store";
import rightArrowIcon from "assets/icons/bx-arrow-back.svg";
import linkExternal from "assets/icons/bx-link-external.svg";
import StepperCustomized from "components/StepperCustomized";
import presentationIcon from "assets/icons/presentation.svg";
import videoContentIcon from "assets/icons/video-camera.svg";
import UpdateSectionDialog from "components/UpdateSectionDialog";
import AddTestingQuestDialog from "components/AddTestingQuestDialog";

const labelsConstant = [
  "Презентация",
  "Видеоматериалы",
  "Литература",
  "Вопросы для самопроверки",
  "Практические упражнения",
  "Тестирование",
];

export default function CourseSection() {
  const { courseId, sectionId } = useParams();
  const content = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user,
    currentCourse,
    sections,
    currentSection,
    courseStatuses,
    tests,
  } = useSelector((store) => store);
  const [sectionTest, setSectionTest] = useState(
    {}
  );
  const [activeStep, setActiveStep] = useState(0);
  const [
    updateSectionDialog,
    setUpdateSectionDialog,
  ] = useState(false);
  const [
    isFinishBtnDisabled,
    setFinishBtnDisabled,
  ] = useState(false);
  const [isLoading, setLoading] = useState(
    !Boolean(currentSection.courseSectionId)
  );
  const [videoModal, setVideoModal] = useState({
    open: false,
    currentVideo: undefined,
  });
  const [documentModal, setDocumentModal] =
    useState({
      open: false,
      currentDocument: undefined,
    });
  const [linkModal, setLinkModal] = useState({
    open: false,
    currentLink: undefined,
  });
  const [timeleft, setTimeLeft] =
    React.useState(0);
  const [labels, setLabels] = useState(
    labelsConstant
  );
  const [data, setData] = useState([]);
  const [isDisabled, setDisabledBtn] =
    useState(false);
  let presentation =
      labels[activeStep] === "Презентация",
    videocontent =
      labels[activeStep] === "Видеоматериалы",
    literature =
      labels[activeStep] === "Литература",
    selfExamination =
      labels[activeStep] ===
      "Вопросы для самопроверки",
    practicalExercises =
      labels[activeStep] ===
      "Практические упражнения",
    testing =
      labels[activeStep] === "Тестирование",
    isUser = !user.isAdmin && !user.isSuperAdmin,
    sectionPassed =
      courseStatuses[
        currentCourse.learningCourseId
      ]?.completedCourseSectionIds.indexOf(
        parseInt(sectionId)
      ) !== -1;

  const onTestFinished = () => {
    if (isUser && !sectionPassed) {
      dispatch(
        setCompleteCourseSection(
          currentCourse.learningCourseId,
          sectionId,
          (success) => {
            if (success) {
              let newCompletedCourseSectionIds =
                [];
              sections[
                currentCourse.learningCourseId
              ].courseSections.forEach(
                (section) => {
                  if (
                    courseStatuses[
                      currentCourse
                        .learningCourseId
                    ].completedCourseSectionIds.indexOf(
                      section.courseSectionId
                    ) !== -1
                  ) {
                    newCompletedCourseSectionIds.push(
                      section.courseSectionId
                    );
                  }
                }
              );
              newCompletedCourseSectionIds.push(
                parseInt(sectionId)
              );
              dispatch(
                updateStoreData({
                  key: "courseStatuses",
                  data: {
                    [currentCourse.learningCourseId]:
                      {
                        completedCourseSectionIds:
                          newCompletedCourseSectionIds,
                      },
                  },
                })
              );
              dispatch(
                createStoreData({
                  alert: {
                    severity: "success",
                    message: "Модуль пройден",
                  },
                })
              );
              navigate(
                `/aboute-course/${currentCourse.learningCourseId}`
              );
            }
          }
        )
      );
    }
    if (isUser && sectionPassed) {
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "Модуль пройден",
          },
        })
      );
      navigate(
        `/aboute-course/${currentCourse.learningCourseId}`
      );
    }
  };

  React.useEffect(() => {
    if (user.isAdmin) {
      setLabels(labelsConstant);
    }
    if (Boolean(currentSection.data)) {
      let data = JSON.parse(currentSection.data);
      setData(data);
    } else if (isUser) {
      setLabels((state) =>
        state.filter(
          (label) =>
            label !== "Литература" &&
            label !==
              "Вопросы для самопроверки" &&
            label !== "Практические упражнения"
        )
      );
    }

    if (isUser) {
      setLabels((state) => {
        let newLabels = [...state],
          absentLabels = [];
        if (Boolean(currentSection.data)) {
          let data = JSON.parse(
            currentSection.data
          );
          if (
            Boolean(
              data.selfExaminationQuestions
                ?.length
            ) &&
            newLabels.indexOf(
              "Вопросы для самопроверки"
            ) === -1
          ) {
            newLabels.push(
              "Вопросы для самопроверки"
            );
          }
          if (
            !Boolean(
              data.selfExaminationQuestions
                ?.length
            ) &&
            newLabels.indexOf(
              "Вопросы для самопроверки"
            ) !== -1
          ) {
            absentLabels.push(
              "Вопросы для самопроверки"
            );
          }
          if (
            Boolean(data.videos?.length) &&
            newLabels.indexOf(
              "Видеоматериалы"
            ) === -1
          ) {
            newLabels.push("Видеоматериалы");
          }
          if (
            !Boolean(data.videos?.length) &&
            newLabels.indexOf(
              "Видеоматериалы"
            ) !== -1
          ) {
            absentLabels.push("Видеоматериалы");
          }
          if (
            Boolean(
              data.practicalExerciseQuestions
                ?.length
            ) &&
            newLabels.indexOf(
              "Практические упражнения"
            ) < 0
          ) {
            newLabels.push(
              "Практические упражнения"
            );
          }
          if (
            !Boolean(
              data.practicalExerciseQuestions
                ?.length
            ) &&
            newLabels.indexOf(
              "Видеоматериалы"
            ) !== -1
          ) {
            absentLabels.push(
              "Практические упражнения"
            );
          }
          if (
            (Boolean(data.documents?.length) ||
              Boolean(data.links?.length)) &&
            newLabels.indexOf("Литература") === -1
          ) {
            newLabels.push("Литература");
          }
          if (
            !Boolean(
              Boolean(data.documents?.length) ||
                Boolean(data.links?.length)
            ) &&
            newLabels.indexOf("Литература") !== -1
          ) {
            absentLabels.push("Литература");
          }
        }
        if (
          Boolean(currentSection.documentHtml) &&
          newLabels.indexOf("Презентация") === -1
        ) {
          newLabels.push("Презентация");
        }
        if (
          !Boolean(currentSection.documentHtml) &&
          newLabels.indexOf("Презентация") !== -1
        ) {
          absentLabels.push("Презентация");
        }
        return newLabels.filter(
          (item) => absentLabels.indexOf(item) < 0
        );
      });
    }
  }, [currentSection, user, isUser]);
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [activeStep]);
  React.useEffect(() => {
    let timer;
    if (timeleft > 0) {
      timer = setInterval(
        () => setTimeLeft(timeleft - 1),
        1000
      );
    }
    return () => clearInterval(timer);
  }, [timeleft]);
  React.useEffect(() => {
    if (
      !Boolean(currentSection.courseSectionId)
    ) {
      setLoading(true);
      dispatch(
        getCourseSections(courseId, (json) => {
          let matchedSection = json
            ? json.courseSections.filter(
                (item) =>
                  item.courseSectionId ===
                  parseInt(sectionId)
              )
            : [];
          if (Boolean(matchedSection.length)) {
            setLoading(false);
            dispatch(
              createStoreData({
                currentSection: matchedSection[0],
              })
            );
          } else {
            dispatch(
              createStoreData({
                alert: {
                  severity: "error",
                  message: "Модуль не найден",
                },
              })
            );
          }
        })
      );
    }
    if (
      !Boolean(currentCourse.learningCourseId)
    ) {
      dispatch(
        getCoursesList(
          !user.isAdmin && !user.isSuperAdmin,
          (json) => {
            let matchedCourse = json.filter(
              (item) =>
                item.learningCourseId ===
                parseInt(courseId)
            );
            if (Boolean(matchedCourse.length)) {
              dispatch(
                createStoreData({
                  currentCourse: matchedCourse[0],
                })
              );
            } else {
              dispatch(
                createStoreData({
                  alert: {
                    severity: "error",
                    message: "Курс не найден",
                  },
                })
              );
            }
          }
        )
      );
    }
  }, [
    courseId,
    currentCourse,
    sectionId,
    currentSection,
    dispatch,
    user,
  ]);
  React.useEffect(() => {
    if (
      !Boolean(tests[sectionId]) &&
      tests[sectionId] !== "Not found"
    ) {
      dispatch(getSectionTests(sectionId));
    }
    if (
      Boolean(tests[sectionId]) &&
      tests[sectionId] !== "Not found"
    ) {
      let sectionTest =
        tests[sectionId].testings[0];
      setSectionTest(sectionTest);
      dispatch(
        getTestingQuests(
          sectionTest.testingId,
          (json) => {
            if (isUser) {
              if (Boolean(json?.length)) {
                setLabels((state) => {
                  if (
                    state.indexOf(
                      "Тестирование"
                    ) < 0
                  ) {
                    return [
                      ...state,
                      "Тестирование",
                    ];
                  } else {
                    return state;
                  }
                });
              } else {
                setLabels((state) =>
                  state.filter(
                    (label) =>
                      label !== "Тестирование"
                  )
                );
              }
            }
          }
        )
      );
    } else if (isUser) {
      setLabels((state) =>
        state.filter(
          (label) => label !== "Тестирование"
        )
      );
    }
  }, [dispatch, tests, sectionId, isUser]);

  // sectionIsNotPassed =
  //   !user.isAdmin && !user.isSuperAdmin
  //     ? courseStatuses[
  //         courseId
  //       ].completedCourseSectionIds.indexOf(
  //         sectionId
  //       ) === -1
  //     : false;
  let questionsProps = {
      data,
      timeleft,
      setTimeLeft,
      setDisabledBtn,
      setFinishBtnDisabled,
    },
    haveDocuments =
      Boolean(data.documents) &&
      Boolean(data.documents.length),
    haveLinks =
      Boolean(data.links) &&
      Boolean(data.links.length),
    haveSelfExaminationQuestions = Boolean(
      data?.selfExaminationQuestions?.length
    ),
    haveVideos =
      Boolean(data.videos) &&
      Boolean(data.videos.length),
    linkNameStyles = {
      width: "100%",
      maxWidth: "calc(100% - 125px)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: 14,
      fontWeight: 600,
      color: "#333333",
      textDecoration: "none",
    },
    introduction =
      currentSection.sectionName ===
        "Приветствие" ||
      currentSection.sectionName === "Введение";
  return (
    <Container>
      {isLoading ? (
        <LoadingIndicator primary />
      ) : (
        <>
          <div
            className="flex_box"
            style={{
              justifyContent: "space-between ",
            }}
          >
            <Breadcrumbs
              style={{ margin: "25px 0px" }}
            >
              <Typography
                variant="body1"
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/aboute-course/${currentCourse.learningCourseId}`
                  )
                }
              >
                {currentCourse.courseName}
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#757575" }}
              >
                {currentSection.sectionName}
              </Typography>
            </Breadcrumbs>
          </div>

          {!introduction && (
            <StepperCustomized
              steps={labels}
              activeStep={activeStep}
              onChangeActiveStep={
                !isUser
                  ? (newActiveStep) => {
                      setActiveStep(
                        newActiveStep
                      );
                      if (
                        labels[activeStep + 1] ===
                          "Вопросы для самопроверки" &&
                        isUser
                      ) {
                        setTimeLeft(
                          haveSelfExaminationQuestions
                            ? data
                                .selfExaminationQuestions[0]
                                .timeToReply * 60
                            : 0
                        );
                      } else {
                        setTimeLeft(0);
                      }
                    }
                  : undefined
              }
              style={{ margin: "50px 0px" }}
            />
          )}
          {presentation && (
            <>
              <div
                className="flex_box"
                style={{
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <Typography variant="h6">
                  Презентация
                </Typography>
                {user.isAdmin &&
                  currentSection.documentHtml && (
                    <Button
                      type="button"
                      variant="outlined"
                      style={{ width: 200 }}
                      onClick={() =>
                        setUpdateSectionDialog(
                          true
                        )
                      }
                    >
                      Редактировать раздел
                    </Button>
                  )}
              </div>
              {currentSection.documentHtml ? (
                <>
                  <div
                    ref={content}
                    className="section_content"
                    dangerouslySetInnerHTML={{
                      __html:
                        currentSection.documentHtml,
                    }}
                  />
                  {introduction &&
                    !sectionPassed && (
                      <div
                        className="flex_box"
                        style={{ marginTop: 30 }}
                      >
                        <PrimaryButton
                          variant="contained"
                          onClick={onTestFinished}
                        >
                          Завершить
                        </PrimaryButton>
                      </div>
                    )}
                </>
              ) : (
                <div
                  className="flex_box"
                  style={{
                    minHeight:
                      "calc(100vh - 500px)",
                  }}
                >
                  <span
                    style={{
                      width: 350,
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={presentationIcon}
                      alt=""
                    />
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#828282",
                        margin: "30px 0px",
                      }}
                    >
                      {user.isAdmin
                        ? "Добавьте материалы в раздел"
                        : "Материалов в данном разделе нет"}
                    </Typography>
                    {user.isAdmin && (
                      <Button
                        type="button"
                        variant="outlined"
                        style={{ width: 160 }}
                        onClick={() =>
                          setUpdateSectionDialog(
                            true
                          )
                        }
                      >
                        Добавить
                      </Button>
                    )}
                  </span>
                </div>
              )}
            </>
          )}
          {videocontent && (
            <>
              <div
                className="flex_box"
                style={{
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <Typography variant="h6">
                  Видеоматериалы
                </Typography>
                {user.isAdmin && haveVideos && (
                  <PrimaryButton
                    size="small"
                    variant="outlined"
                    style={{
                      backgroundColor:
                        "transparent",
                      color: "#1058FB",
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      setVideoModal({
                        open: true,
                        currentVideo: undefined,
                      });
                    }}
                    startIcon={
                      <img
                        src={plusIcon}
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        alt=""
                      />
                    }
                  >
                    Добавить видео
                  </PrimaryButton>
                )}
              </div>
              {haveVideos ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    gap: 20,
                    marginTop: 27,
                  }}
                >
                  {data.videos.map((video) => {
                    return (
                      <VideoItem
                        key={video.id}
                        video={video}
                        setVideoModal={
                          setVideoModal
                        }
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  className="flex_box"
                  style={{
                    minHeight:
                      "calc(100vh - 500px)",
                  }}
                >
                  <span
                    style={{
                      width: 350,
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={videoContentIcon}
                      alt=""
                    />
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#828282",
                        margin: "30px 0px",
                      }}
                    >
                      Видеоматериалов в данном
                      разделе еще нет
                    </Typography>
                    {user.isAdmin && (
                      <Button
                        type="button"
                        variant="outlined"
                        style={{ width: 160 }}
                        onClick={() =>
                          setVideoModal({
                            open: true,
                            currentVideo:
                              undefined,
                          })
                        }
                      >
                        Добавить
                      </Button>
                    )}
                  </span>
                </div>
              )}
            </>
          )}
          {literature && (
            <>
              <div
                className="flex_box"
                style={{
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <Typography variant="h6">
                  Литература
                </Typography>
              </div>
              {haveLinks ||
              haveDocuments ||
              user.isAdmin ? (
                <div
                  className="flex_box"
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 30,
                    marginTop: 30,
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginBottom: haveLinks
                          ? 14
                          : 0,
                      }}
                    >
                      Ссылки
                    </Typography>
                    {haveLinks &&
                      data.links.map(
                        (link, index) => (
                          <React.Fragment
                            key={link.id}
                          >
                            {index !== 0 && (
                              <Divider
                                style={{
                                  backgroundColor:
                                    "#BDBDBD",
                                  margin:
                                    "24px 0px",
                                }}
                              />
                            )}
                            <div
                              style={{
                                display: "flex",
                                alignItems:
                                  "flex-start",
                                justifyContent:
                                  "flex-start",
                                gap: 8,
                              }}
                            >
                              <img
                                src={linkExternal}
                                alt=""
                              />
                              <div
                                style={{
                                  width:
                                    "calc(100% - 40px)",
                                }}
                              >
                                <div
                                  className="flex_box"
                                  style={{
                                    justifyContent:
                                      "space-between",
                                    marginBottom: 8,
                                    gap: 20,
                                  }}
                                >
                                  <Link
                                    href={
                                      link.url.includes(
                                        "http"
                                      )
                                        ? link.url
                                        : `//${link.url}`
                                    }
                                    target="_blank"
                                    rel="canonical noreferrer noopener"
                                    style={
                                      linkNameStyles
                                    }
                                  >
                                    {link.url}
                                  </Link>
                                  {user.isAdmin && (
                                    <Typography
                                      onClick={() =>
                                        setLinkModal(
                                          {
                                            open: true,
                                            currentLink:
                                              link,
                                          }
                                        )
                                      }
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color:
                                          "#1058FB",
                                        cursor:
                                          "pointer",
                                      }}
                                    >
                                      Редактировать
                                    </Typography>
                                  )}
                                </div>
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color:
                                      "#333333",
                                  }}
                                >
                                  {
                                    link.description
                                  }
                                </Typography>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      )}
                    {user.isAdmin && (
                      <PrimaryButton
                        size="small"
                        variant="outlined"
                        style={{
                          backgroundColor:
                            "transparent",
                          color: "#1058FB",
                          fontWeight: 600,
                          marginTop: 24,
                        }}
                        onClick={() =>
                          setLinkModal({
                            open: true,
                            currentLink:
                              undefined,
                          })
                        }
                        startIcon={
                          <img
                            src={plusIcon}
                            style={{
                              width: 20,
                              height: 20,
                            }}
                            alt=""
                          />
                        }
                      >
                        Добавить ссылку
                      </PrimaryButton>
                    )}
                  </div>
                  <div style={{ width: "50%" }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginBottom:
                          haveDocuments ? 14 : 0,
                      }}
                    >
                      Документы
                    </Typography>
                    {haveDocuments &&
                      data.documents.map(
                        (document, index) => {
                          return (
                            <React.Fragment
                              key={document.id}
                            >
                              {index !== 0 && (
                                <Divider
                                  style={{
                                    backgroundColor:
                                      "#BDBDBD",
                                    margin:
                                      "24px 0px",
                                  }}
                                />
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems:
                                    "flex-start",
                                  justifyContent:
                                    "flex-start",
                                  gap: 8,
                                }}
                              >
                                <img
                                  src={fileGrey}
                                  alt=""
                                />
                                <div
                                  style={{
                                    width:
                                      "calc(100% - 40px)",
                                  }}
                                >
                                  <div
                                    className="flex_box"
                                    style={{
                                      justifyContent:
                                        "space-between",
                                      marginBottom: 8,
                                      gap: 20,
                                    }}
                                  >
                                    <Link
                                      download={
                                        document.name
                                      }
                                      href={
                                        document.file
                                      }
                                      target="_blank"
                                      rel="canonical noopener noreferrer"
                                      style={
                                        linkNameStyles
                                      }
                                    >
                                      {document.name
                                        ? document.name
                                        : "Название не найдено"}
                                    </Link>
                                    {user.isAdmin && (
                                      <Typography
                                        onClick={() =>
                                          setDocumentModal(
                                            {
                                              open: true,
                                              currentDocument:
                                                document,
                                            }
                                          )
                                        }
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 400,
                                          color:
                                            "#1058FB",
                                          cursor:
                                            "pointer",
                                        }}
                                      >
                                        Редактировать
                                      </Typography>
                                    )}
                                  </div>
                                  <Typography
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color:
                                        "#333333",
                                    }}
                                  >
                                    {
                                      document.description
                                    }
                                  </Typography>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    {user.isAdmin && (
                      <PrimaryButton
                        size="small"
                        variant="outlined"
                        style={{
                          backgroundColor:
                            "transparent",
                          color: "#1058FB",
                          fontWeight: 600,
                          marginTop: 24,
                        }}
                        onClick={() =>
                          setDocumentModal({
                            open: true,
                            currentDocument:
                              undefined,
                          })
                        }
                        startIcon={
                          <img
                            src={plusIcon}
                            style={{
                              width: 20,
                              height: 20,
                            }}
                            alt=""
                          />
                        }
                      >
                        Добавить документ
                      </PrimaryButton>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="flex_box"
                  style={{
                    minHeight:
                      "calc(100vh - 500px)",
                  }}
                >
                  <span
                    style={{
                      width: 350,
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={literatureIcon}
                      alt=""
                    />
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#828282",
                        margin: "30px 0px",
                      }}
                    >
                      {user.isAdmin
                        ? "Добавьте материалы в раздел"
                        : "Материалов в данном разделе нет"}
                    </Typography>
                  </span>
                </div>
              )}
            </>
          )}
          {selfExamination && (
            <Questions
              {...questionsProps}
              tab="selfExamination"
            />
          )}
          {practicalExercises && (
            <Questions
              {...questionsProps}
              tab="practicalExercises"
            />
          )}
          {testing ? (
            isUser ? (
              <PassTest
                sectionTest={sectionTest}
                setFinishBtnDisabled={
                  setFinishBtnDisabled
                }
              />
            ) : (
              <EditTest
                sectionTest={sectionTest}
              />
            )
          ) : null}
          <div
            className="flex_box"
            style={{
              margin: "30px 0px",
              justifyContent: "flex-start",
            }}
          >
            {isUser ? (
              activeStep + 1 < labels.length &&
              !introduction ? (
                <PrimaryButton
                  onClick={() => {
                    let isQuestions =
                        labels[activeStep + 1] ===
                          "Вопросы для самопроверки" ||
                        labels[activeStep + 1] ===
                          "Практические упражнения",
                      isTesting =
                        labels[activeStep + 1] ===
                        "Тестирование";
                    setActiveStep(
                      (state) => state + 1
                    );
                    if (isQuestions && isUser) {
                      setDisabledBtn(true);
                    }
                    if (
                      activeStep + 2 ===
                        labels.length &&
                      (isQuestions || isTesting)
                    ) {
                      setFinishBtnDisabled(true);
                    }
                    if (
                      labels[activeStep + 1] ===
                        "Вопросы для самопроверки" &&
                      isUser
                    ) {
                      setTimeLeft(
                        haveSelfExaminationQuestions
                          ? data
                              .selfExaminationQuestions[0]
                              .timeToReply * 60
                          : 0
                      );
                    } else {
                      setTimeLeft(0);
                    }
                  }}
                  endIcon={
                    <img
                      src={rightArrowIcon}
                      alt=""
                    />
                  }
                  style={{ width: 230 }}
                  disabled={isDisabled}
                >
                  Следующий раздел
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  variant="green"
                  style={{ width: 150 }}
                  onClick={onTestFinished}
                  disabled={isFinishBtnDisabled}
                >
                  Завершить
                </PrimaryButton>
              )
            ) : null}
          </div>
        </>
      )}
      <UpdateSectionDialog
        open={updateSectionDialog}
        handleClose={() =>
          setUpdateSectionDialog(false)
        }
      />
      <NewVideo
        open={videoModal.open}
        handleClose={() =>
          setVideoModal({
            open: false,
            currentVideo: undefined,
          })
        }
        currentVideo={videoModal.currentVideo}
      />
      <NewDocument
        open={documentModal.open}
        handleClose={() =>
          setDocumentModal({
            open: false,
            currentDocument: undefined,
          })
        }
        currentDocument={
          documentModal.currentDocument
        }
      />
      <NewLink
        open={linkModal.open}
        handleClose={() =>
          setLinkModal({
            open: false,
            currentLink: undefined,
          })
        }
        currentLink={linkModal.currentLink}
      />
      <AddTestingQuestDialog />
    </Container>
  );
}
