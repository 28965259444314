import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { subscribeCourse } from "api";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "shared/Buttons";
import { createStoreData } from "store";

export default function CoursePaper({ course, updateHandler, showProgress }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, courseStatuses, sections } = useSelector((store) => store);
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.down("xl"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const subscribeToCourse = (subscribe) => {
    if (!Boolean(user.sessionId)) {
      dispatch(createStoreData({ authorizationDialog: true }));
    } else {
      if (!user.isAdmin && !user.isSuperAdmin && subscribe) {
        dispatch(subscribeCourse(course.learningCourseId));
      }
    }
    dispatch(createStoreData({ currentCourse: course }));
    navigate(`/aboute-course/${course.learningCourseId}`);
  };
  const getProcent = () => {
    let statusesLength =
        courseStatuses[course.learningCourseId].completedCourseSectionIds
          .length,
      sectionsLength = sections[course.learningCourseId].courseSections.length;
    return Math.round((statusesLength * 100) / sectionsLength);
  };
  const ActionButton = (
    <PrimaryButton
      size="small"
      onClick={
        user.isAdmin && updateHandler
          ? updateHandler
          : () => {
              subscribeToCourse(!course.subscribed);
            }
      }>
      {user.isAdmin && "Редактировать"}
      {((course.subscribed && !user.isAdmin) || user.isSuperAdmin) &&
        "Просмотреть"}
      {!course.subscribed &&
        !user.isAdmin &&
        !user.isSuperAdmin &&
        "Начать обучение"}
    </PrimaryButton>
  );
  return (
    Boolean(course.courseName) && (
      <div
        className="flex_box course_paper"
        style={{ flexDirection: sm ? "column-reverse" : "row" }}>
        <div
          className="flex_box_vertical"
          style={{
            width: sm
              ? "100%"
              : xl
              ? "calc(100% - 340px)"
              : "calc(100% - 390px)",
            padding: sm ? 16 : 24,
            alignItems: "flex-start",
          }}>
          <div
            className="flex_box"
            style={{
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 22,
            }}>
            <Typography
              variant="subtitle1"
              className={classes.courseName}
              onClick={() => subscribeToCourse(false)}>
              {course.courseName}
            </Typography>
            {!sm && ActionButton}
          </div>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Inter",
              color: "#333333",
              marginBottom: 16,
            }}>
            {course.description}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              marginBottom: 16,
            }}>
            Для кого
          </Typography>
          <div className="flex_box">
            {course.targetAudience.split("/").map((employee, index) => (
              <Typography
                key={employee + index}
                variant="body2"
                className={classes.customChip}
                style={{ marginRight: 8 }}>
                {employee}
              </Typography>
            ))}
          </div>
          {sm && ActionButton}
        </div>
        <img
          src={course.courserImageBase64.split("nameSplitter*")[1]}
          style={{ width: sm ? "100%" : 390 }}
          alt=""
        />
        {showProgress && (
          <div className="progress_indicator">
            <div
              className="flex_box"
              style={{ padding: "4px 11px", justifyContent: "space-between" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#2BD328",
                  fontWeight: 700,
                  margin: 0,
                }}>{`${getProcent()}%`}</Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#757575",
                  fontWeight: 400,
                  margin: 0,
                }}>
                Пройдено
              </Typography>
            </div>
            <div className="track">
              <div className="bar" style={{ width: `${getProcent()}%` }} />
            </div>
          </div>
        )}
      </div>
    )
  );
}

export const chipStyles = {
  padding: "8px 12px",
  backgroundColor: "#F1F2F3",
  borderRadius: 4,
};

const useStyles = makeStyles((theme) => ({
  customChip: chipStyles,
  courseName: {
    width: "calc(100% - 200px)",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: "#1058FB",
    },
  },
}));
