import { Dialog, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  getAccount,
  getDepartments,
  getPositions,
  addOrUpdateUserProfile,
} from "api";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import close from "assets/icons/Close.svg";
import { PrimaryButton } from "shared/Buttons";
import LoadingIndicator from "shared/LoadingIndicator";
import { createStoreData } from "store";
import GetFormParser from "./GetFormParser";

export default function UpdateUserDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    accounts,
    currentAccount,
    positions,
    departments,
    organizations,
    updateUserDialog,
  } = useSelector((store) => store);
  const [accountForUpdate, setAccountForUpdate] = useState({ userId: false });
  const [isLoading, setLoading] = useState(true);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (updateUserDialog) {
      if (!Boolean(accounts[currentAccount.userId])) {
        setLoading(true);
        dispatch(getAccount(currentAccount.userId));
      }
      if (
        Boolean(accounts[currentAccount.userId]) &&
        accountForUpdate.userId !== currentAccount.userId
      ) {
        setLoading(true);
        let userAccount = accounts[currentAccount.userId];
        dispatch(
          getDepartments(userAccount.organizationId, (success) => {
            if (success) {
              dispatch(
                getPositions(userAccount.organizationId, (success) => {
                  setLoading(false);
                })
              );
            }
          })
        );
        setAccountForUpdate(userAccount);
      }
    }
  }, [accounts, dispatch, updateUserDialog, currentAccount, accountForUpdate]);

  const fields = [
    {
      name: "userName",
      label: "Фамилия Имя Отчество",
      type: "text",
      placeholder: "ФИО",
    },
    {
      name: "phone",
      label: "Номер телефона",
      type: "text",
      placeholder: "+996 555 555 555",
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Введите ваш Email",
    },
    {
      name: "role",
      label: "Права доступа",
      type: "select",
      placeholder: "Выберите роль",
      variants: [
        { label: "Супер админ", value: "SuperAdmin" },
        { label: "Админ", value: "Admin" },
        { label: "Пользователь", value: "User" },
      ],
    },
    {
      name: "organizationId",
      label: "Организация",
      type: "select",
      variants: Boolean(organizations) ? organizations : [],
    },
    {
      name: "departmentId",
      label: "Отдел",
      type: "select",
      variants: Boolean(departments[accountForUpdate.organizationId])
        ? departments[accountForUpdate.organizationId].parsedData
        : [],
    },
    {
      name: "positionId",
      label: "Должность",
      type: "select",
      variants: Boolean(positions[accountForUpdate.organizationId])
        ? positions[accountForUpdate.organizationId].parsedData
        : [],
    },
  ];
  const submitHandler = (values) => {
    setLoading(true);
    dispatch(
      addOrUpdateUserProfile(values, (success) => {
        setLoading(false);
        if (success) {
          dispatch(getAccount(currentAccount.userId));
          dispatch(
            createStoreData({
              updateUserDialog: false,
            })
          );
        }
      })
    );
  };
  const refreshState = () => {
    setAccountForUpdate({ userId: false });
    dispatch(createStoreData({ updateUserDialog: false }));
  };
  return (
    <Dialog
      open={updateUserDialog}
      onClose={refreshState}
      classes={{ paper: classes.content }}
      scroll="body">
      <DialogContent dividers={false}>
        <div
          className="flex_box"
          style={{ justifyContent: "flex-end", marginBottom: 20 }}>
          <img
            src={close}
            alt="close_icon"
            onClick={refreshState}
            style={{ cursor: "pointer" }}
          />
        </div>
        {!isLoading ? (
          <GetFormParser
            fields={fields}
            initialValues={{
              userName: Boolean(accountForUpdate.userName)
                ? accountForUpdate.userName
                : "",
              phone: Boolean(accountForUpdate.phone)
                ? accountForUpdate.phone
                : "",
              email: Boolean(accountForUpdate.email)
                ? accountForUpdate.email
                : "",
              role: Boolean(accountForUpdate.role) ? accountForUpdate.role : "",
              organizationId: Boolean(accountForUpdate.organizationId)
                ? accountForUpdate.organizationId
                : "",
              departmentId: Boolean(accountForUpdate.departmentId)
                ? accountForUpdate.departmentId
                : "",
              positionId: Boolean(accountForUpdate.positionId)
                ? accountForUpdate.positionId
                : "",
            }}
            validationSchema={Yup.object({
              userName: Yup.string().required("Поле обьязательна"),
              phone: Yup.string().required("Поле обьязательна"),
              email: Yup.string().required("Поле обьязательна"),
              role: Yup.string(),
              organizationId: Yup.string(),
              departmentId: Yup.string(),
              positionId: Yup.string(),
            })}
            onSubmit={submitHandler}
            action={
              <div className="flex_box" style={{ width: "100%" }}>
                <PrimaryButton
                  type="submit"
                  style={{ width: sm ? "100%" : 200 }}>
                  Сохранить
                </PrimaryButton>
              </div>
            }
            enableReinitialize
          />
        ) : (
          <LoadingIndicator primary />
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 796,
    minHeight: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: "24px 26px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 16px",
      },
    },
  },
}));
