import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { makeStyles, useTheme } from "@mui/styles";
import { ValidatedTextInput } from "shared/TextInput";
import { PrimaryButton } from "shared/Buttons";

import EmblemOfKyrgyzstan from "assets/icons/Emblem_of_Kyrgyzstan.svg";
import unicefLogo from "assets/icons/UNICEF_Logo.svg";
import close from "assets/icons/Close.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authorization } from "api";

export default function AuthorizationDialog({ open = false, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exception, setException] = useState({
    phone: "",
    password: "",
  });
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const submitHandler = (values) => {
    dispatch(authorization(values, refreshState));
  };
  const refreshState = () => {
    setException({
      phone: "",
      password: "",
    });
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.content }}
      scroll="body">
      <DialogContent dividers={false}>
        <div
          className="flex_box"
          style={{ position: "relative", justifyContent: "flex-end" }}>
          {!sm && (
            <div className="unsplash">
              <div
                style={{
                  padding: "40px 44px",
                  position: "relative",
                  zIndex: 1,
                }}>
                <div
                  className="flex_box"
                  style={{ justifyContent: "flex-start" }}>
                  <IconButton onClick={() => navigate("/")}>
                    <img src={EmblemOfKyrgyzstan} alt="Emblem_of_Kyrgyzstan" />
                  </IconButton>
                  <Typography
                    variant="caption"
                    style={{ maxWidth: 240, color: "#FFFFFF" }}>
                    Министерство труда, социального обеспечения и миграции
                    Кыргызской Республики
                  </Typography>
                </div>
                <img
                  style={{ marginTop: 30, marginLeft: 10 }}
                  src={unicefLogo}
                  alt="unicef logo"
                />
              </div>
              <span className="blue_cover" />
            </div>
          )}

          <div
            style={{
              width: sm ? "100%" : "calc(100% - 366px)",
              position: "relative",
              padding: sm ? 22 : "32px 40px",
            }}>
            <Formik
              onSubmit={submitHandler}
              initialValues={{
                phone: "",
                password: "",
              }}>
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Typography
                    variant="h6"
                    style={{ marginBottom: sm ? 15 : 25 }}>
                    Вход в систему
                  </Typography>

                  <Typography variant="body2" className={classes.subtitle}>
                    email или номер телефона
                  </Typography>
                  <div
                    className="flex_box_vertical"
                    style={{
                      minHeight: sm ? 80 : 90,
                      justifyContent: "flex-start",
                      marginTop: sm ? 4 : 8,
                    }}>
                    <ValidatedTextInput
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      className={classes.textField}
                      margin="dense"
                      name="phone"
                    />
                    <Typography
                      variant="caption"
                      component="div"
                      style={{ marginTop: 8, color: "#757575" }}>
                      {exception["phone"]}
                    </Typography>
                  </div>

                  <Typography variant="body2" className={classes.subtitle}>
                    Пароль
                  </Typography>
                  <div
                    className="flex_box_vertical"
                    style={{
                      minHeight: sm ? 80 : 90,
                      justifyContent: "flex-start",
                      marginTop: sm ? 4 : 8,
                    }}>
                    <ValidatedTextInput
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={classes.textField}
                      margin="dense"
                      name="password"
                      type="password"
                    />
                    <Typography
                      variant="caption"
                      component="div"
                      style={{ marginTop: 8, color: "#757575" }}>
                      {exception["password"]}
                    </Typography>
                  </div>

                  <PrimaryButton
                    variant="contained"
                    type="submit"
                    style={{ marginTop: 20 }}
                    fullWidth>
                    Войти
                  </PrimaryButton>

                  <div className="flex_box" style={{ marginTop: 36 }}>
                    <Divider
                      style={{
                        backgroundColor: "#C4C4C4",
                        width: "calc(50% - 86px)",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{ color: "#757575", margin: "0px 16px" }}>
                      У вас нет аккаунта?
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: "#C4C4C4",
                        width: "calc(50% - 86px)",
                      }}
                    />
                  </div>
                  <div className="flex_box">
                    <PrimaryButton
                      onClick={() => {
                        navigate("/registration");
                        handleClose();
                      }}
                      variant="text"
                      style={{ marginTop: 10 }}>
                      Зарегистрироваться
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
            <img
              className="close_icon"
              src={close}
              alt="close_icon"
              onClick={refreshState}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    color: "#FFF",
    textAlign: "center",
    padding: "0px 10px",
  },
  subtitle: {
    fontWeight: "600 !important",
    color: "#000000",
  },
  content: {
    minWidth: 796,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  textField: {
    height: 44,
  },
}));
