import axios from "axios";
import cookie_js from "cookie_js";
import { createStoreData } from "store/AppReducer";
import { initialState } from "store/initialState";

export const appAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  contentType: "application/json",
});

export const makeRequest = (endpoint, dispatch, method, data) => {
  function errorHandler({ response }) {
    if (
      (response.data.message || response.data.title) === "информация не найдена"
    ) {
      return;
    }
    if (response?.data.status === 401) {
      cookie_js.remove("kurs_sessionId");
      dispatch(
        createStoreData({
          ...initialState,
          user: { sessionId: "" },
          alert: {
            message: response.data.message || response.data.title,
            severity: "error",
          },
        })
      );
      return;
    }
    let isMessageDefined = Boolean(response?.data.message);
    dispatch(
      createStoreData({
        alert: {
          message: isMessageDefined
            ? response?.data.message
            : `Внимание: Ошибка в запросе на endpoint ${endpoint}`,
          severity: "error",
        },
      })
    );
    console.error(`Внимание: Ошибка в запросе на endpoint ${endpoint}
      Текс сообщения: ${
        isMessageDefined ? response?.data.message : "Не найден"
      }.`);
  }
  let sessionId = cookie_js.get("kurs_sessionId"),
    registrationId = cookie_js.get("app_registrationId"),
    parsedEndpoint = endpoint
      .replace("{sessionId}", sessionId)
      .replace("{registrationId}", registrationId);

  switch (method) {
    case "get": {
      return appAxios
        .get(parsedEndpoint)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    case "post": {
      return appAxios
        .post(parsedEndpoint, data)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    case "delete": {
      return appAxios
        .delete(parsedEndpoint, data)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    default: {
      dispatch(
        createStoreData({
          alert: {
            message: `Метод запроса не найден: ${parsedEndpoint}`,
            severity: "error",
          },
        })
      );
    }
  }
};

export * from "./utils";
export * from "./POST_requests";
export * from "./GET_requests";
