import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";

import { createStoreData } from "store";
import { PrimaryButton } from "shared/Buttons";
import editIcon from "assets/icons/bxs-edit-alt.svg";
import LoadingIndicator from "shared/LoadingIndicator";
import trashIcon from "assets/icons/bx-trash-alt-outlined.svg";
import { createOrUpdateSection, getYouTubeVideoData } from "api";
import { makeStyles } from "@mui/styles";

export const VideoItem = ({ video, setVideoModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, currentSection } = useSelector((store) => store);
  const [title, setTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isTitleLoading, setTitleLoading] = useState(false);
  const [watchVideoModal, setWatchVideo] = useState(false);
  let embedLink = `https://www.youtube.com/embed/${
    video.url.includes("watch?v=")
      ? video.url.split("watch?v=")[1].split("&")[0]
      : "Видео не найдена"
  }`;

  React.useEffect(() => {
    if (!title && !isLoading) {
      setTitleLoading(true);
      getYouTubeVideoData(video.url, (data) => {
        if (data) {
          setTitle(data.title || "Не найден");
        }
        setTitleLoading(false);
      });
    }
  }, [title, isLoading, video]);
  const deleteVideo = () => {
    dispatch(
      createStoreData({
        confirmRemoveDialog: {
          open: true,
          confirm: () => {
            let newData = Boolean(currentSection.data)
                ? JSON.parse(currentSection.data)
                : {},
              videos = [];

            newData.videos.forEach((item) => {
              if (item.id !== video.id) {
                videos.push(item);
              }
            });
            setLoading(true);
            newData.videos = videos;
            currentSection.data = JSON.stringify(newData);

            dispatch(
              createOrUpdateSection(currentSection, (sections) => {
                setLoading(false);
                if (sections) {
                  let matchedSection = sections
                    ? sections.courseSections.filter(
                        (item) =>
                          item.courseSectionId ===
                          parseInt(currentSection.courseSectionId)
                      )
                    : [];
                  if (Boolean(matchedSection.length)) {
                    dispatch(
                      createStoreData({
                        currentSection: matchedSection[0],
                        alert: { severity: "success", message: "Успешно" },
                      })
                    );
                  }
                }
              })
            );
          },
        },
      })
    );
  };
  const openVideoModal = (event) => {
    event.stopPropagation();
    setWatchVideo(true);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 24,
      }}>
      <div onClick={openVideoModal} style={{ cursor: "pointer" }}>
        <iframe
          width="220"
          height="146"
          src={embedLink}
          onClick={openVideoModal}
          title={`YouTube video player-${video.id}`}
          style={{ pointerEvents: "none" }}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </div>
      <div>
        <Typography
          variant="body1"
          component="div"
          onClick={openVideoModal}
          className={classes.videoTitle}
          style={{ fontWeight: 600 }}>
          {isTitleLoading ? <LoadingIndicator small primary /> : title}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 10, marginBottom: 20 }}>
          {video.description || "Нет описание"}
        </Typography>
        {user.isAdmin ? (
          isLoading ? (
            <LoadingIndicator small primary />
          ) : (
            <div
              className="flex_box"
              style={{ justifyContent: "flex-start", gap: 20 }}>
              <PrimaryButton
                size="small"
                variant="outlined"
                style={{
                  backgroundColor: "transparent",
                  color: "#1058FB",
                  fontSize: 14,
                  fontWeight: 400,
                  width: 170,
                  minHeight: 32,
                  maxHeight: 32,
                  padding: "7px 20px",
                }}
                onClick={() => {
                  setVideoModal({ open: true, currentVideo: video });
                }}
                startIcon={
                  <img
                    src={editIcon}
                    style={{ width: 20, height: 20 }}
                    alt=""
                  />
                }>
                Редактировать
              </PrimaryButton>
              <Button
                size="small"
                variant="delete"
                style={{
                  width: 120,
                  minHeight: 32,
                  maxHeight: 32,
                  fontSize: 14,
                  fontWeight: 400,
                  padding: "7px 20px",
                }}
                startIcon={
                  <img
                    src={trashIcon}
                    style={{ width: 20, height: 20 }}
                    alt=""
                  />
                }
                onClick={deleteVideo}>
                Удалить
              </Button>
            </div>
          )
        ) : null}
      </div>

      <Dialog
        open={watchVideoModal}
        onClose={() => setWatchVideo(false)}
        classes={{ paper: classes.paper }}>
        <DialogContent>
          <iframe
            width="100%"
            src={embedLink}
            onClick={openVideoModal}
            title={`YouTube video player-${video.id}`}
            style={{ minHeight: "70vh" }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "70%", minHeight: "70vh" },
  videoTitle: {
    cursor: "pointer",
    "&:hover": {
      color: "#1058FB",
      textDecoration: "underline",
    },
  },
}));
