import { Button } from "@mui/material";
import { styled } from "@mui/styles";

export const HeaderThemeButton = styled(Button)(() => ({
  "&.MuiButton-root": {
    height: 44,
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 0,
    color: "#FFFFFF",
    padding: "12px 32px",
    borderColor: "#FFFFFF",
  },
  "&.MuiButton-root:hover": {
    borderColor: "#FFFFFF",
  },
}));

export const PrimaryButton = styled(Button)(({ theme, size, variant }) => {
  let isSmall = size === "small",
    isDefaultVariant = variant === "text";
  return {
    "&.MuiButton-root": {
      height: isSmall ? 32 : 40,
      minHeight: 32,
      fontSize: 14,
      fontWeight: 700,
      borderRadius: 4,
      padding: isSmall ? "7px 16px" : "15",
      color: isDefaultVariant ? "#1058FB" : "#FFFFFF",
      textAlign: "center",
      borderColor: "#1058FB",
      backgroundColor: isDefaultVariant ? "transparent" : "#1058FB",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "&.Mui-disabled": {
      backgroundColor: "#ccc",
      color: "#fff",
    },
    "&.MuiButton-root:hover": {
      borderColor: isDefaultVariant ? "none" : "#1058FB",
      backgroundColor: isDefaultVariant ? "transparent" : "#1058FB",
    },
  };
});
