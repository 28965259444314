import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { nanoid } from "nanoid";
import * as Yup from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";

import { createStoreData } from "store";
import crossIcon from "assets/icons/Close.svg";
import { PrimaryButton } from "shared/Buttons";
import plusIcon from "assets/icons/bx-plus.svg";
import fileGrey from "assets/icons/bxs-file-grey.svg";
import { ValidatedTextInput } from "shared/TextInput";
import {
  createOrUpdateSection,
  uploadFile,
} from "api";
import LoadingIndicator from "shared/LoadingIndicator";

const initialValuesConstant = {
  description: "",
};

export default function NewDocument({
  open,
  handleClose,
  currentDocument,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileUploader = React.useRef();
  const { currentSection } = useSelector(
    (store) => store
  );
  const [initialValues, setInitialValues] =
    useState(initialValuesConstant);
  const [isLoading, setLoading] = useState(false);
  const [document, setDocument] = useState("");
  let isItUpdateMode =
    typeof currentDocument !== "undefined";

  React.useEffect(() => {
    setInitialValues(
      isItUpdateMode
        ? {
            description:
              currentDocument.description,
          }
        : initialValuesConstant
    );
    setDocument(
      isItUpdateMode
        ? { name: currentDocument.name }
        : ""
    );
  }, [isItUpdateMode, currentDocument]);

  const submitHandler = (values) => {
    if (!document) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Выберите файл",
          },
        })
      );
      return;
    }

    let formData = new FormData();
    formData.append("file", document);

    setLoading(true);
    dispatch(
      uploadFile(formData, (url) => {
        if (url) {
          let newData = Boolean(
              currentSection.data
            )
              ? JSON.parse(currentSection.data)
              : {},
            documents = Boolean(newData.documents)
              ? newData.documents
              : [];

          if (isItUpdateMode) {
            let newDocuments = documents.map(
              (item) => {
                if (
                  item.id === currentDocument.id
                ) {
                  values.id = currentDocument.id;
                  values.file = url;
                  values.name = document.name;
                  return values;
                }
                return item;
              }
            );
            documents = newDocuments;
          } else {
            values.id = nanoid();
            values.file = url;
            values.name = document.name;
            documents.push(values);
          }
          newData.documents = documents;
          currentSection.data =
            JSON.stringify(newData);
          startRequest(currentSection);
        } else {
          setLoading(false);
          dispatch(
            createStoreData({
              alert: {
                severity: "error",
                message:
                  "Возникла ошибка при загрузке файла",
              },
            })
          );
        }
      })
    );
  };
  const deleteHandler = () => {
    let newData = Boolean(currentSection.data)
        ? JSON.parse(currentSection.data)
        : {},
      documents = [];
    newData.documents.forEach((item) => {
      if (item.id !== currentDocument.id) {
        documents.push(item);
      }
    });
    setLoading(true);
    newData.documents = documents;
    currentSection.data = JSON.stringify(newData);
    startRequest(currentSection);
  };
  const startRequest = (currentSection) => {
    dispatch(
      createOrUpdateSection(
        currentSection,
        (sections) => {
          setLoading(false);
          if (sections) {
            let matchedSection = sections
              ? sections.courseSections.filter(
                  (item) =>
                    item.courseSectionId ===
                    parseInt(
                      currentSection.courseSectionId
                    )
                )
              : [];
            if (Boolean(matchedSection.length)) {
              dispatch(
                createStoreData({
                  currentSection:
                    matchedSection[0],
                  alert: {
                    severity: "success",
                    message: "Успешно",
                  },
                })
              );
              closeHandler();
            }
          }
        }
      )
    );
  };
  const onUpload = async (event) => {
    let newDocument = event.target.files[0];
    setDocument(newDocument);
    event.target.value = "";
  };
  const closeHandler = () => {
    setDocument("");
    setInitialValues(initialValuesConstant);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <div
          className="flex_box"
          style={{
            justifyContent: "space-between",
            marginBottom: 24,
          }}
        >
          <Typography variant="subtitle1">
            {isItUpdateMode
              ? "Обновление документа"
              : "Добавление документа"}
          </Typography>
          <IconButton onClick={closeHandler}>
            <img
              src={crossIcon}
              alt="cross"
            />
          </IconButton>
        </div>
        <Formik
          onSubmit={submitHandler}
          initialValues={initialValues}
          validationSchema={Yup.object({
            description: Yup.string().required(
              "Поле не заполнена"
            ),
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              {document ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 11,
                  }}
                >
                  <img
                    src={fileGrey}
                    alt=""
                  />
                  <Typography
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#333333",
                    }}
                  >
                    {document.name
                      ? document.name
                      : "Название не найдено"}
                  </Typography>
                  <Typography
                    onClick={() =>
                      isItUpdateMode
                        ? fileUploader.current?.click()
                        : setDocument("")
                    }
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: isItUpdateMode
                        ? "#1058FB"
                        : "#EB5757",
                      cursor: "pointer",
                    }}
                  >
                    {isItUpdateMode
                      ? "редактировать"
                      : "Удалить"}
                  </Typography>
                </div>
              ) : (
                <PrimaryButton
                  size="small"
                  variant="outlined"
                  style={{
                    border: "none",
                    backgroundColor:
                      "transparent",
                    color: "#1058FB",
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    fileUploader.current?.click();
                  }}
                  startIcon={
                    <img
                      src={plusIcon}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      alt=""
                    />
                  }
                >
                  Добавить документ (форматы:
                  .doc, .pdf, .ppt, .xls)
                </PrimaryButton>
              )}
              <Typography
                variant="body2"
                className={classes.label}
                style={{ marginTop: 20 }}
              >
                Описание документа *
              </Typography>
              <ValidatedTextInput
                name="description"
                rows={4}
                multiline
                placeholder="Введите описание видео"
              />
              <div
                className="flex_box"
                style={{ gap: 20, marginTop: 24 }}
              >
                <Button
                  type="button"
                  variant={
                    isItUpdateMode
                      ? "delete"
                      : "outlined"
                  }
                  disabled={isLoading}
                  style={{ width: 160 }}
                  onClick={
                    isItUpdateMode
                      ? deleteHandler
                      : closeHandler
                  }
                >
                  {isItUpdateMode
                    ? "Удалить"
                    : "Отмена"}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  style={{ width: 160 }}
                >
                  {isLoading ? (
                    <LoadingIndicator small />
                  ) : isItUpdateMode ? (
                    "Сохранить"
                  ) : (
                    "Добавить"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <input
          type="file"
          accept=".doc, .pdf, .ppt, .xls"
          ref={fileUploader}
          onChange={onUpload}
          hidden
        />
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: "start",
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    color: "#FFF",
    marginBottom: "8px !important",
  },
  paper: {
    minWidth: 620,
  },
}));
