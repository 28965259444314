import React, { useState } from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { createStoreData } from "store";
import close from "assets/icons/Close.svg";
import { createOrUpdateSection } from "api";
import { ValidatedTextInput } from "shared/TextInput";
import LoadingIndicator from "shared/LoadingIndicator";

const initialValuesConstant = {
  question: "",
  answer: "",
  timeToReply: "",
};

export default function NewSelfExaminationQuestionModal({
  open,
  closeHandler,
  currentQuestion,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentSection } = useSelector((store) => store);
  const [dataState, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesConstant);
  let isItUpdateMode = typeof currentQuestion !== "undefined",
    haveSomeData = Boolean(Object.keys(dataState).length);

  React.useEffect(() => {
    if (isItUpdateMode) {
      setInitialValues(currentQuestion);
    }
    if (Boolean(currentSection.data)) {
      setData(JSON.parse(currentSection.data));
    }
  }, [isItUpdateMode, currentQuestion, currentSection, open]);

  const submitHandler = (values) => {
    if (!isLoading) {
      let selfExaminationQuestions =
        haveSomeData && Boolean(dataState.selfExaminationQuestions)
          ? dataState.selfExaminationQuestions
          : [];

      setLoading(true);
      if (isItUpdateMode) {
        selfExaminationQuestions = selfExaminationQuestions.map((question) =>
          question.id === currentQuestion.id
            ? { id: currentQuestion.id, ...values }
            : question
        );
      } else {
        selfExaminationQuestions.push({
          id: 1 + selfExaminationQuestions.length,
          ...values,
        });
      }
      let data = JSON.stringify(
        haveSomeData
          ? {
              ...dataState,
              selfExaminationQuestions,
            }
          : { selfExaminationQuestions }
      );
      saveSectionData(data);
    }
  };
  const deleteHandler = () => {
    dispatch(
      createStoreData({
        confirmRemoveDialog: {
          open: true,
          confirm: () => {
            let selfExaminationQuestions =
                dataState.selfExaminationQuestions.filter(
                  (item) => item.id !== currentQuestion.id
                ),
              data = JSON.stringify(
                haveSomeData
                  ? {
                      ...dataState,
                      selfExaminationQuestions,
                    }
                  : { selfExaminationQuestions }
              );
            setLoading(true);
            saveSectionData(data);
          },
        },
      })
    );
  };
  const saveSectionData = (data) => {
    dispatch(
      createOrUpdateSection(
        {
          ...currentSection,
          data,
        },
        (json) => {
          setLoading(false);
          if (json) {
            let matchedSection = json
              ? json.courseSections.filter(
                  (item) =>
                    item.courseSectionId ===
                    parseInt(currentSection.courseSectionId)
                )
              : [];
            if (Boolean(matchedSection.length)) {
              setLoading(false);
              dispatch(createStoreData({ currentSection: matchedSection[0] }));
            } else {
              dispatch(
                createStoreData({
                  alert: {
                    severity: "error",
                    message: "Модуль не найден",
                  },
                })
              );
            }
            dispatch(
              createStoreData({
                alert: { severity: "success", message: "Успешно" },
              })
            );
            refreshState();
          }
        }
      )
    );
  };
  const refreshState = () => {
    setInitialValues(initialValuesConstant);
    closeHandler();
  };
  return (
    <Dialog
      open={open}
      onClose={refreshState}
      classes={{ paper: classes.paper }}>
      <DialogContent style={{ width: 600 }}>
        <div
          className="flex_box"
          style={{ justifyContent: "space-between", marginBottom: 20 }}>
          <Typography variant="h6">
            {isItUpdateMode ? "Обновление вопроса" : "Добавление вопроса"}
          </Typography>
          <img
            src={close}
            alt="close_icon"
            onClick={refreshState}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Formik
          onSubmit={submitHandler}
          initialValues={initialValues}
          validationSchema={Yup.object({
            question: Yup.string().required("Заполните поле"),
            answer: Yup.string().required("Заполните поле"),
            timeToReply: Yup.string().required("Заполните поле"),
          })}
          enableReinitialize>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Typography variant="body2" className={classes.label}>
                Текст вопроса
              </Typography>
              <ValidatedTextInput
                name="question"
                placeholder="Введите сюда свой вопрос"
                rows={4}
                multiline
              />
              <Typography variant="body2" className={classes.label}>
                Экспертное мнение
              </Typography>
              <ValidatedTextInput
                name="answer"
                placeholder="Введите правильный ответ на вопрос"
                rows={4}
                multiline
              />
              <Typography variant="body2" className={classes.label}>
                Время для ответа в минутах
              </Typography>
              <ValidatedTextInput
                type="number"
                name="timeToReply"
                placeholder="Время для ответа"
              />
              <div className="flex_box" style={{ gap: 20, marginTop: 24 }}>
                <Button
                  type="button"
                  variant={isItUpdateMode ? "delete" : "outlined"}
                  disabled={isLoading}
                  style={{ width: 160 }}
                  onClick={isItUpdateMode ? deleteHandler : closeHandler}>
                  {isItUpdateMode ? "Удалить" : "Отмена"}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  style={{ width: 160 }}>
                  {isLoading ? (
                    <LoadingIndicator small />
                  ) : isItUpdateMode ? (
                    "Сохранить"
                  ) : (
                    "Добавить"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 620,
  },
  label: {
    fontWeight: "600 !important",
    color: "#000000",
    marginTop: "32px !important",
    marginBottom: "8px !important",
  },
}));
