import React, { useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import plusIcon from "assets/icons/bx-plus.svg";
import playIcon from "assets/icons/bx-play-circle.svg";
import { chipStyles } from "components/CoursePaper";
import LoadingIndicator from "shared/LoadingIndicator";
import { PrimaryButton } from "shared/Buttons";
import AddNewCourseDialog from "components/AddNewCourseDialog";
import { getCourseSections, getCoursesList, getStatusPassageCourse } from "api";

import { createStoreData } from "store";
import course1 from "assets/images/course-1.svg";
import course2 from "assets/images/course-2.svg";
import course3 from "assets/images/course-3.svg";
import Certificate from "components/Certificate";
import { deleteCourse } from "api/DELETE_requests";
import AddSectionDialog from "components/AddSection";

export const defaultCovers = [course1, course2, course3];

export default function AbouteCourse() {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, currentCourse, sections, courseStatuses } = useSelector(
    (store) => store
  );
  const [sectionsArray, setSectionsArray] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isCoursePassed, setCoursePassed] = useState(false);
  const [viewCerificate, setViewCerticate] = useState(false);
  const [newCourseDialog, setNewCourseDialog] = useState(false);
  const [newSectionDialog, setNewSectionDialog] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  let isUser = !user.isAdmin && !user.isSuperAdmin;

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  React.useEffect(() => {
    let passedSections =
        isUser && courseStatuses[currentCourse.learningCourseId]
          ? courseStatuses[currentCourse.learningCourseId]
              .completedCourseSectionIds
          : [],
      passedCurrentSectionsIds = [];
    sectionsArray.forEach((item) => {
      let passed = passedSections.indexOf(item.courseSectionId) > -1;
      if (passed) {
        passedCurrentSectionsIds.push(item.courseSectionId);
      }
    });
    setCoursePassed(
      Boolean(passedCurrentSectionsIds.length === sectionsArray.length)
    );
  }, [isUser, courseStatuses, sectionsArray, currentCourse]);
  React.useEffect(() => {
    if (!Boolean(currentCourse.learningCourseId)) {
      dispatch(
        getCoursesList(!user.isAdmin && !user.isSuperAdmin, (json) => {
          let matchedCourse = json.filter(
            (item) => item.learningCourseId === parseInt(params.id)
          );
          if (Boolean(matchedCourse.length)) {
            dispatch(createStoreData({ currentCourse: matchedCourse[0] }));
          } else {
            dispatch(
              createStoreData({
                alert: {
                  severity: "error",
                  message: "Курс не найден",
                },
              })
            );
          }
        })
      );
    }
  }, [currentCourse, dispatch, params, user]);
  React.useEffect(() => {
    if (Boolean(currentCourse.learningCourseId)) {
      if (
        !user.isAdmin &&
        !user.isSuperAdmin &&
        !Boolean(courseStatuses[currentCourse.learningCourseId])
      ) {
        dispatch(getStatusPassageCourse(currentCourse.learningCourseId));
      }
      if (!Boolean(sections[currentCourse.learningCourseId])) {
        setLoading(true);
        dispatch(getCourseSections(currentCourse.learningCourseId));
      }
    }
    if (Boolean(sections[currentCourse.learningCourseId])) {
      setLoading(false);
      setSectionsArray(
        sections[currentCourse.learningCourseId].courseSections.sort(
          (a, b) => a.sectionSequenceNumber - b.sectionSequenceNumber
        )
      );
    }
  }, [sections, dispatch, currentCourse, sectionsArray, courseStatuses, user]);
  return Boolean(currentCourse.learningCourseId) ? (
    <>
      <AddNewCourseDialog
        open={newCourseDialog}
        course={currentCourse}
        handleClose={() => setNewCourseDialog(false)}
      />
      <AddSectionDialog
        open={newSectionDialog}
        handleClose={() => setNewSectionDialog(false)}
      />
      <div
        className="course_cover"
        style={{
          backgroundImage: `url(${
            Boolean(currentCourse.courserImageBase64)
              ? currentCourse.courserImageBase64.split("nameSplitter*")[1]
              : defaultCovers[Math.round(Math.random() * 2)]
          })`,
        }}
      />
      <Container>
        <div
          className="flex_box_vertical"
          style={{
            width: "100%",
            marginTop: sm ? 122 : 368,
            marginBottom: 40,
            alignItems: "flex-start",
          }}>
          <div
            className="flex_box"
            style={{
              width: "100%",
              margin: "32px 0px",
              justifyContent: user.isAdmin ? "space-between" : "flex-start",
              alignItems: sm ? "flex-start" : "center",
            }}>
            <Typography variant="h1" style={{ marginRight: sm ? 10 : 0 }}>
              {currentCourse.courseName}
            </Typography>
            {user.isAdmin && (
              <div className="flex_box" style={{ minWidth: 300 }}>
                <PrimaryButton
                  size="small"
                  onClick={() => setNewCourseDialog(true)}>
                  Редактирование
                </PrimaryButton>
                <Button
                  style={{ marginLeft: 20 }}
                  className={classes.deleteButton}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    dispatch(
                      createStoreData({
                        confirmRemoveDialog: {
                          open: true,
                          confirm: () => {
                            dispatch(
                              deleteCourse(
                                currentCourse.learningCourseId,
                                (success) => {
                                  if (success) {
                                    dispatch(getCoursesList(false));
                                    navigate("/");
                                  }
                                }
                              )
                            );
                          },
                        },
                      })
                    );
                  }}>
                  Удалить курс
                </Button>
              </div>
            )}
          </div>
          <div
            className="flex_box"
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: sm ? "column" : "row",
            }}>
            <div
              style={{
                width: sm ? "100%" : "calc(50% - 30px)",
                marginBottom: sm ? 40 : 0,
              }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginBottom: 24 }}>
                Общая характеристика
              </Typography>
              <Typography variant="body1" style={{ marginBottom: 32 }}>
                {currentCourse.description}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  marginBottom: 16,
                }}>
                Для кого
              </Typography>
              <div
                className="flex_box"
                style={{ justifyContent: "flex-start" }}>
                {currentCourse.targetAudience
                  .split(",")
                  .map((employee, index) => (
                    <Typography
                      key={employee + index}
                      variant="body2"
                      className={classes.customChip}
                      style={{ marginRight: 8 }}>
                      {employee}
                    </Typography>
                  ))}
              </div>
              {isUser && (
                <>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      marginTop: 40,
                      marginBottom: 16,
                    }}>
                    Сертификат о прохождении курса
                  </Typography>
                  {isCoursePassed ? (
                    <Typography
                      variant="body1"
                      onClick={() => setViewCerticate(true)}
                      style={{ color: "#1058FB", cursor: "pointer" }}>
                      Посмотреть сертификат
                    </Typography>
                  ) : (
                    <Typography variant="body1" style={{ color: "#757575" }}>
                      После прохождения курса здесь появится ваш сертификат
                    </Typography>
                  )}
                </>
              )}
            </div>
            <div style={{ width: sm ? "100%" : "calc(50% - 30px)" }}>
              <div
                className="flex_box"
                style={{ justifyContent: "space-between", marginBottom: 24 }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Разделы курса
                </Typography>
                {user.isAdmin && (
                  <PrimaryButton
                    size="small"
                    style={{
                      backgroundColor: "transparent",
                      color: "#1058FB",
                      fontWeight: 600,
                    }}
                    onClick={() => setNewSectionDialog(true)}
                    startIcon={
                      <img
                        src={plusIcon}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                    }>
                    Добавить раздел
                  </PrimaryButton>
                )}
              </div>
              {Boolean(sectionsArray.length) ? (
                sectionsArray.map((section, sectionIndex) => {
                  let passedSections =
                      user.isAdmin || user.isSuperAdmin
                        ? []
                        : courseStatuses[currentCourse.learningCourseId]
                            .completedCourseSectionIds,
                    isCurrentSectionPassed =
                      passedSections.indexOf(section.courseSectionId) !== -1,
                    isPrevSectionPassed =
                      sectionIndex > 0 &&
                      passedSections.indexOf(
                        sectionsArray[sectionIndex - 1].courseSectionId
                      ) !== -1,
                    status =
                      (sectionIndex === 0 &&
                        passedSections.indexOf(section.courseSectionId) ===
                          -1) ||
                      (!isCurrentSectionPassed && isPrevSectionPassed) ||
                      user.isAdmin ||
                      user.isSuperAdmin
                        ? "available"
                        : passedSections.indexOf(section.courseSectionId) !== -1
                        ? "passed"
                        : "not_available";
                  return (
                    <div
                      className="flex_box course_paper"
                      key={section.courseSectionId}
                      style={{
                        padding: "12px 16px",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}>
                      <div
                        className="flex_box"
                        style={{
                          width:
                            user.isAdmin || user.isSuperAdmin
                              ? "100%"
                              : "calc(100% - 151px)",
                          alignItems: Boolean(section.hasVideo)
                            ? "flex-start"
                            : "center",
                          justifyContent: "space-between",
                          cursor:
                            status !== "not_available"
                              ? "pointer"
                              : "not-allowed",
                        }}
                        onClick={() => {
                          if (status !== "not_available") {
                            dispatch(
                              createStoreData({ currentSection: section })
                            );
                            navigate(
                              `/course-section/${currentCourse.learningCourseId}/${section.courseSectionId}`
                            );
                          }
                        }}>
                        <div
                          className={`section_number status_${status} flex_box`}>
                          {sectionIndex + 1}
                        </div>
                        <span className={classes.sectionName}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 600,
                              width: "100%",
                            }}>
                            {section.sectionName}
                          </Typography>
                          {Boolean(section.hasVideo) && (
                            <div
                              className="flex_box"
                              style={{
                                justifyContent: "flex-start",
                                marginTop: 8,
                                marginBottom: 12,
                              }}>
                              <img src={playIcon} alt="play icon" />
                              <Typography
                                variant="body1"
                                style={{ color: "#757575", marginLeft: 8 }}>
                                Видеоматериалы
                              </Typography>
                            </div>
                          )}
                          {status === "viewed" && (
                            <Typography
                              variant="body2"
                              style={{ color: "#28C128" }}>
                              Тестирование пройдено
                            </Typography>
                          )}
                        </span>
                      </div>
                      <div>
                        {!user.isAdmin && (
                          <div
                            className={`custom_chip status_${status} flex_box`}>
                            {status === "passed" && "Просмотрено"}
                            {status === "available" && "Доступен"}
                            {status === "not_available" && "Закрыт"}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : isLoading ? (
                <LoadingIndicator primary />
              ) : (
                <p className="body1" style={{ color: "red" }}>
                  Нет разделов
                </p>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Dialog
        open={viewCerificate}
        onClose={() => setViewCerticate(false)}
        classes={{ paper: classes.certificatePaper }}>
        <DialogContent>
          <Certificate />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <LoadingIndicator primary />
  );
}

const useStyles = makeStyles((theme) => ({
  customChip: chipStyles,
  sectionName: {
    width: "calc(100% - 52px)",
    "&:hover": {
      color: "#1058FB",
    },
  },
  deleteButton: {
    "&.MuiButton-root": {
      minHeight: 32,
      fontWeight: 700,
      color: "#ed1528",
      borderColor: "#ed1528",
    },
    "&.MuiButton-root:hover": {
      borderColor: "#ed1528",
    },
  },
  certificatePaper: {
    "&.MuiPaper-root": {
      minWidth: "50%",
      maxHeight: 842,
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
}));
