import React from "react";
import { Typography, useTheme, useMediaQuery, Container } from "@mui/material";

import studyIcon from "assets/icons/study.svg";
import diplomaIcon from "assets/icons/diploma.svg";
import calendarIcon from "assets/icons/calendar.svg";

import course1 from "assets/images/course-1.svg";
import course2 from "assets/images/course-2.svg";
import course3 from "assets/images/course-3.svg";
// import PieChartComponent from "components/PieChart";
// import CustomDataTable from "components/CustomDataTable";
import CoursePaper from "components/CoursePaper";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesList } from "api";
import { HeaderThemeButton } from "shared/Buttons";
import { createStoreData } from "store";

export default function Main() {
  const dispatch = useDispatch();
  const { user, coursesList } = useSelector((store) => store);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (Boolean(user.sessionId)) {
      dispatch(getCoursesList(!user.isAdmin && !user.isSuperAdmin));
    }
  }, [dispatch, user]);
  return (
    <div>
      <div className="main_bg_image" alt="Tumar_Ibragimov_2018">
        <div className="cover" />
      </div>

      <div
        className="flex_box"
        style={{
          width: "100%",
          height: `calc(100vh - ${sm ? "82px" : "100px"})`,
          position: "relative",
        }}>
        <div
          className="flex_box_vertical"
          style={{
            textAlign: "center",
            padding: sm ? "0px 16px" : 0,
          }}>
          <Typography variant="h1" style={{ color: "#FFFFFF" }}>
            Образовательная он-лайн платформа
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ color: "#FFFFFF", marginTop: sm ? 25 : 40 }}>
            Для специалистов, работающих в области защиты детей и юстиции для
            детей
          </Typography>
        </div>

        {sm && (
          <HeaderThemeButton
            onClick={() =>
              dispatch(createStoreData({ authorizationDialog: true }))
            }
            variant="outlined"
            style={{
              width: "calc(100% - 32px)",
              color: "#FFFFFF",
              borderColor: "#FFFFFF",
              borderRadius: 0,
              position: "absolute",
              left: 16,
              right: 16,
              bottom: 40,
            }}>
            Войти
          </HeaderThemeButton>
        )}
      </div>

      <div
        style={{
          padding: sm ? "50px 16px" : lg ? 50 : "90px 126px 120px 126px",
          backgroundColor: "#1058FB",
        }}>
        <Typography
          variant="h1"
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            marginBottom: lg ? 30 : 77,
          }}>
          О платформе
        </Typography>
        <div
          className="flex_box"
          style={{
            flexDirection: sm ? "column" : "row",
            alignItems: sm ? "center" : "flex-start",
          }}>
          {aboutPlatformData.map((item, index) => (
            <div
              key={index}
              className="flex_box_vertical"
              style={{
                width: sm ? "100%" : "33%",
                padding: lg ? 15 : 30,
                color: "#FFFFFF",
              }}>
              <img
                src={item.icon}
                style={{ width: lg ? 50 : "auto" }}
                alt={item.title}
              />
              <Typography
                variant="subtitle1"
                style={{
                  marginTop: 33,
                  marginBottom: 16,
                  textAlign: "center",
                }}>
                {item.title}
              </Typography>
              <Typography variant="body1" style={{ textAlign: "center" }}>
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      {Boolean(coursesList.length) && (
        <Container>
          <Typography
            variant="h1"
            style={{
              textAlign: "center",
              margin: lg ? "24px 0px" : "48px 0px",
            }}>
            Курсы
          </Typography>
          {coursesList.map((course) => (
            <CoursePaper key={course.learningCourseId} course={course} />
          ))}
        </Container>
      )}

      {/* <section
        className='flex_box_vertical'
        style={{ padding: "64px 180px", backgroundColor: "#F1F2F3" }}
      >
        <Typography
          variant='h1'
          style={{ textAlign: "center", color: "#000000" }}
        >
          Статистика
        </Typography>
        <div
          className='flex_box'
          style={{
            width: "100%",
            justifyContent: "space-between",
            marginTop: 48,
          }}
        >
          <div className='statistics_chart_wrapper'>
            <Typography variant='h6'>Курсы</Typography>
            <PieChartComponent name='Курсы' data={courseChartData} />
            <div className='pie_chart_legend'>
              {courseChartData.map((item, index) => (
                <div
                  key={index}
                  className='flex_box'
                  style={{ padding: "10px 0px", justifyContent: "flex-start" }}
                >
                  <div
                    className='circle_icon'
                    style={{ border: `5px solid ${item.itemStyle.color}` }}
                  />
                  <Typography
                    variant='body2'
                    style={{ color: "#000", padding: "0px 12px" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant='body2'
                    style={{ color: "#000", fontWeight: 700 }}
                  >{`${item.value}%`}</Typography>
                </div>
              ))}
            </div>
          </div>
          <div className='statistics_chart_wrapper'>
            <Typography variant='h6'>Должность</Typography>
            <PieChartComponent name='Курсы' data={positionChartData} />
            <div className='pie_chart_legend'>
              {positionChartData.map((item, index) => (
                <div
                  key={index}
                  className='flex_box'
                  style={{ padding: "10px 0px", justifyContent: "flex-start" }}
                >
                  <div
                    className='circle_icon'
                    style={{ border: `5px solid ${item.itemStyle.color}` }}
                  />
                  <Typography
                    variant='body2'
                    style={{ color: "#000", padding: "0px 12px" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant='body2'
                    style={{ color: "#000", fontWeight: 700 }}
                  >{`${item.value}%`}</Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Typography
          variant='h6'
          style={{ width: "100%", textAlign: "start", padding: "28px 0px" }}
        >
          Курс “Юстиция для детей”
        </Typography>
        <CustomDataTable data={courseDataTable} />
        <Typography
          variant='h6'
          style={{ width: "100%", textAlign: "start", padding: "28px 0px" }}
        >
          Курс “Образование для детей”
        </Typography>
        <CustomDataTable data={courseDataTable} />
      </section> */}
    </div>
  );
}

const aboutPlatformData = [
  {
    icon: studyIcon,
    title: "Возможности",
    description:
      "Прохождение курса засчитывается как специализация по вопросам юстиции для детей и позволяет осуществлять профессиональную деятельность, связанную с детьми.",
  },
  {
    icon: diplomaIcon,
    title: "Cертификат",
    description: "По итогам успешного прохождения курса выдается сертификат. ",
  },
  {
    icon: calendarIcon,
    title: "Продолжительность",
    description:
      "Общее количество засчитываемых часов зависит от конкретно выбранного курса",
  },
];
export const coursesData = [
  {
    id: 0,
    image: course1,
    title: "Юстиция для детей",
    text: "Он-лайн курс направлен на обучение должностных лиц и адвокатов, задействованных в системе юстиции для детей, а также любых иных лиц, которые интересуются вопросами правового положения ребенка в Кыргызской Республики.",
    duration: "48",
    forWhom: ["Адвокаты", "Сотрудники МВД", "Социальные работники"],
  },
  {
    id: 1,
    image: course2,
    title: "Образование для детей",
    text: "Он-лайн курс направлен на обучение должностных лиц и адвокатов, задействованных в системе юстиции для детей, а также любых иных лиц, которые интересуются вопросами правового положения ребенка в Кыргызской Республики.",
    duration: "32",
    forWhom: ["Адвокаты", "Социальные работники"],
  },
  {
    id: 2,
    image: course3,
    title: "Длинное название курса, которое может поместиться в две строки",
    text: "Он-лайн курс направлен на обучение должностных лиц и адвокатов, задействованных в системе юстиции для детей, а также любых иных лиц, которые интересуются вопросами правового положения ребенка в Кыргызской Республики.",
    duration: "16",
    forWhom: [
      "Адвокаты",
      "Сотрудники МВД",
      "Социальные работники",
      "Детские психологи",
    ],
  },
];
// const courseChartData = [
//   {
//     value: 76,
//     itemStyle: { color: "#DE272C" },
//     name: "Юстиция для детей",
//   },
//   {
//     value: 9,
//     itemStyle: { color: "#FD5B2F" },
//     name: "Образование для детей",
//   },
//   {
//     value: 8,
//     itemStyle: { color: "#1E9F70" },
//     name: "Социальная защита детей",
//   },
//   {
//     value: 7,
//     itemStyle: { color: "#4487F4" },
//     name: "Юстиция для детей",
//   },
// ];
// const positionChartData = [
//   {
//     value: 62,
//     itemStyle: { color: "#FBBC00" },
//     name: "Специалисты",
//   },
//   {
//     value: 8,
//     itemStyle: { color: "#1E9F70" },
//     name: "Инспекторы",
//   },
//   {
//     value: 9,
//     itemStyle: { color: "#FD5B2F" },
//     name: "Следователи",
//   },
//   {
//     value: 7,
//     itemStyle: { color: "#DE272C" },
//     name: "Адвокаты",
//   },
//   {
//     value: 7,
//     itemStyle: { color: "#4487F4" },
//     name: "Судьи",
//   },
//   {
//     value: 7,
//     itemStyle: { color: "#625FF1" },
//     name: "Другие",
//   },
// ];
// const courseDataTable = [
//   [
//     {
//       name: "Организация",
//       props: {
//         xs: 6,
//       },
//     },
//     {
//       name: "Женщины",
//       props: {
//         xs: 2,
//       },
//     },
//     {
//       name: "Мужчины",
//       props: {
//         xs: 2,
//       },
//     },
//     {
//       name: "Итого пройдено",
//       props: {
//         xs: 2,
//       },
//     },
//   ],
//   ["Министерство труда и социального развития КР", "456", "180", "636"],
//   ["Министерство внутренних дел КР", "200", "203", "403"],
//   ["Министерство Образования КР", "456", "180", "636"],
// ];
