import { initialState } from "./initialState";

export const appReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_STORE_DATA:
      return { ...store, ...payload };

    case UPDATE_STORE_DATA:
      // if payload.data is array, update with new array or with new object
      return {
        ...store,
        [payload.key]: Array.isArray(payload.data)
          ? [...store[payload.key], ...payload.data]
          : { ...store[payload.key], ...payload.data },
      };
    default:
      return store;
  }
};

export const CREATE_STORE_DATA = "CREATE_STORE_DATA";
export const UPDATE_STORE_DATA = "UPDATE_STORE_DATA";

export const createStoreData = (data) => ({
  type: CREATE_STORE_DATA,
  payload: data,
});
export const updateStoreData = (data) => ({
  type: UPDATE_STORE_DATA,
  payload: data,
});
